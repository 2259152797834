@import "../../assets/common/Colors";



  .cart-error-message{
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
    margin-bottom: 80px;
    .cart-error-header{
      font-family: GilroyRegular,"sans-serif";
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 115%;
      color: #4E4E4E;
    }
    .cart-error-text{
      margin: auto;
    }
    .cart-error-para{
      font-family: GilroyRegular,"sans-serif";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 115%;
      color: #787A91;
    }
    .btn{
      max-width: 254px;
    }
  }


@media screen and (max-width: 600px) {
  .cart-error-message{
    flex-direction: column;
    text-align: center;
    align-items: center;
    .cart-error-img{
      width: 155px;
      margin-bottom: 32px;
    }
    .cart-error-header{
      font-size: 20px;
      line-height: 115%;
      margin-bottom: 12px;
    }
    .cart-error-text{
      margin-bottom: 24px;
    }
    .cart-error-para{
      font-size: 14px;
      line-height: 115%;
      max-width: 256px;
    }
    .btn{
      max-width: 254px;
    }
  }
}

