@import "../../assets/common/Colors";
@import "../../assets/common/Typography";


.course-component {
  .title-pdf{
    color: #4E4E4E;
    font-size: 32px;
    font-family: "GilroyBold",sans-serif;
    line-height: 115%;
    margin-bottom: 18px;
  }
  .pdf-wrapper{
    background: #FFFFFF;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px 20px;
    margin-bottom: 40px;
    .inside-pdf-wrapper{
      width: 100%;
      font-family: "GilroyRegular",sans-serif;
      font-size: 20px;
      line-height: 170.1%;
      color: #4E4E4E;
      display: flex;
      justify-content: space-between;
      .pdf_icon{
        margin-right: 24px;
      }
      .text-view{
        font-family: "GilroyRegular",sans-serif;
        font-size: 24px;
        line-height: 170.1%;
        color: #E9B51C;
        margin: 0;
        cursor: pointer;
      }
    }
  }
  .professor-detail {
    background-image: url("../../assets/images/wave-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 37px;
    padding-bottom: 44px;

    .course-name {
      font-family: "GilroyBold", sans-serif;
      font-size: 36px;
      line-height: 115%;
      color: $vampire_grey;
      margin: 0 0 8px 0;
      .course-level{
        display: none;
      }
    }


    .professor-name {
      @include heading5();
      color: #4E4E4E;
      margin: 0;
    }
    .course-expiry-date{
      display: none;
    }
  }
  .video-section{
    padding: 40px 0;
    .active-video{
      margin: 0;
      font-family: "GilroyBold", sans-serif;
      font-size: 20px;
      line-height: 115%;
      color: #4E4E4E;
      padding: 20px ;
      @media(min-width:992px){
        font-size: 36px;
        padding: 0 0 20px 0;
      }
    }
    .video-react {
      width: 100% !important;
      height: 408px !important;
      .video-react-big-play-button{
        display: none;
      }
      .video-react-poster{
        background-size: 35px;
      }
    }
    .course-accordian-component{
      .accordion{
        .accordion__item{

            .accordion__heading{
              .accordion__button{
                padding:8px 24px;
                .lesson-heading-component{
                  .large-device-heading{
                    .accordian-arrow{
                      width: 24px;
                    }
                    .module-name{
                      font-size: 16px;
                    }
                    .duration{
                      display: none;
                    }
                  }
                }
              }
            }

          .accordion__panel{
            .lesson-item-component{
              .left-section{
                img{
                  width: 26px;
                }
                .lesson-name{
                  font-size: 16px;
                }

              }
              .paid-right-section{
                // display: none;
              }
              &:not(:last-child) {
                padding-bottom: 8px;
              }
              &:not(:first-child) {
                padding-top: 8px;
              }
            }
          }
        }
      }
    }
  }
  .auto-transcript-section{
    .title{
      color: $vampire_grey;
      font-size: 32px;
      font-family: "GilroyBold",sans-serif;
      line-height: 115%;
      margin-bottom: 18px;
    }
    .description-wrapper{
      .description{
        @include heading6_medium();
        color: $vampire_grey;
      }
      .read-more-content,.read-less-content{
        display: none;
      }
    }
  }
}

@media screen and (max-width:600px) {
  .course-component {
    .video-js {
        width: 100%
    }
    .title-pdf{
font-size: 24px;
      margin-bottom: 16px;
    }
    .pdf-wrapper{
padding: 16px 12px;
      .inside-pdf-wrapper{
          font-size: 12px;

        .pdf_icon{
          width: 16px;
          height: 16px;
        }
        .text-view{
          font-size: 14px;
        }
      }
    }



      .professor-detail {
      padding-top: 14px;
      padding-bottom: 16px;
      .course-name {
        font-size: 20px;
        .course-level{
          display: block;
          @include paragraph4_semibold();
          margin-left: 8px;
        }
      }
      .professor-name {
        @include paragraph3_semibold();
        margin-bottom: 8px;
      }
      .course-expiry-date{
        display: block;
        font-family: "GilroySemoBold",sans-serif;
        font-size: 8px;
        line-height: 170.1%;
        color: $vampire_grey;
        margin: 0;
      }
    }
    .video-section{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      .layout-container{
        padding:0;
      }
      .video-react {
        width: 100% !important;
        height: 200px !important;
      }
      .grid-columns{
        padding: 0;
      }
      .course-accordian-component{
        margin: 40px 24px;
      }
    }
    .auto-transcript-section{
      .title{
        font-size: 24px;
        margin-bottom: 40px;
      }
      .description-wrapper{
        position: relative;
        .description{
          @include heading6_medium();
          color: $vampire_grey;
          height: 250px;
          overflow: hidden;
          transition: 0.5s
        ;
        }
        .read-more-content,.read-less-content{
          display: flex;
          justify-content: center;
          align-items: end;
          cursor: pointer;
          background: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1));
          position: absolute;
          bottom: -40px;
          padding: 15px 0;
          width: 100%;
          height: 100px;
          transition: 0.5s;
          span{
            @include paragraph3();
            color: #E9B51C;
            font-family: "GilroyMedium",sans-serif;
          }
          .arrow-down{
            margin-left: 8px;
          }
        }
        .read-less-content {
          display: none;
          .arrow-down{
            transform: rotate(180deg);
          }
        }

      }
    }
  }
}

