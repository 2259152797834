@media (max-width: 600px) {
    .layout-container{
        padding: 0 24px;
    }
}

@media (min-width: 601px) {
    .layout-container{
        padding: 0 80px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .layout-container{
        padding: 0 24px;
    }
}
