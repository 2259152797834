@import "../../assets/common/Typography";
@import "../../assets/common/Colors";


.course-list-component {
  .empty-message{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular",sans-serif;
    color: $vampire_grey;
  }
  .course-filter-component {
    background: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 5px rgba(78, 78, 78, 0.1);
    border-radius: 9.19205px;
    border: 1px solid #D5D5D5;
    width: 100%;
    .filter-input-section {
      position: relative;

      .filter_input {
        width: 100%;
        padding: 25px 200px;
        border: none;
        outline: none;
        border-radius: 9.19205px;
        font-family: "GilroyBold", sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: rgba(78, 78, 78, 0.75);
      }

      .search_icon {
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .filter_button {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-right: 1px solid #D5D5D5;
        height: 100%;

        button {
          background: #FFFBEC;
          border-radius: 9.19205px 0px 0px 9.19205px;
          height: 100%;
          width: 180px;
          border: none;
          font-family: "GilroyRegular", sans-serif;
          font-size: 24px;
          line-height: 28px;
          color: $vampire_grey;

          &:focus {
            border: none;
            outline: none;
          }

          .filter_bitton_img {
            padding-right: 24px;
          }
        }
      }
    }
    #course-filter-modal {
      .modal-content {
        position: relative;
      }
      .modal-close-btn {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 26px;
        top: 26px;
        cursor: pointer;
        z-index: 2;
      }
      .modal-content-section {
        padding: 40px 80px;
        .large-device-filter-attributes{
          .filter-property-title{
            font-family: "GilroyBold",sans-serif;
            font-size: 14px;
            line-height: 170.1%;
            color: $vampire_grey;
            margin: 0 0 8px 0;
            &:not(:first-child){
              margin-top: 40px;
            }
          }
          .filter-property-item {
            display: flex;
            border-bottom: 1px solid #4E4E4E40;
            align-items: center;
            padding: 16px 0;

          }
          .filter-action-section{
            display: flex;
            gap: 15px;
          }
          .apply-filter-btn{
            margin: 25px 0;
            background-color: #e9b51c;
            color: #fff;
            width: 100%;
            padding: 10px;
            text-transform: capitalize;
          }
          .clear-filter-btn{
            color: #e9b51c;
            margin: 25px 0;
            background-color: #fff;
            width: 100%;
            padding: 10px;
            text-transform: capitalize;
          }
        }
        .small-device-filter-attributes{
          display: none;
          height: 100%;
          .content-section {
            height: 100%;
            .row{
              height: 100%;
            }
            .btn {
              //width: 100px;
            }
            .left-column {
              background-color: #FCF4DC;
              .header {
                font-family: "GilroyRegular",sans-serif;
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                margin: 24px 0;
                color: #4E4E4E;
              }
              .attribute-item{
                .title {
                  font-family: "GilroySemoBold",sans-serif;
                  font-size: 14px;
                  line-height: 170.1%;
                  color: rgba(78, 78, 78, 0.75);
                  padding: 24px 0;
                  text-align: center;
                  margin: 0;
                }
                &.active{
                  background-color: #FFFFFF;
                  .title{
                    font-family: "GilroyBold",sans-serif;
                  }
                }
              }
            }
            .right-column{
              .button-section {
                text-align: end;
                margin-top: 20px;
                .btn{
                  color: #E9B51C;
                  span{
                    border-bottom: 2px solid #E9B51C !important;
                  }
                }
              }
              .attribute-content-section {
                margin: 40px 24px;
              }
            }
            .action-btn-section {
              width: 100%;
              position: absolute;
              bottom: 0;
              display: flex;
              box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.1);
              .action-btn{
                padding: 15px 0;
                width: 50%;
                text-align: center;
                font-family: 'Gilroy', sans-serif;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
              }
              .close-btn {
                color: #4E4E4E;
                background-color: white;
              }
              .apply-btn {
                color: white;
                background: #E9B51C;
              }
            }
          }
        }
      }

    }
  }

  .professor-list-section {
    .ourExperts {
      margin: 40px 0;
      padding: 0 80px;
    }
  }

  .search-result-title-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .result-title{
      @include heading5;
      line-height: 29px;
      color: $vampire_grey;
      padding: 32px 0;
    }
    .view-all-btn {
      display: none;
      button{
        color: $squash;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        .right_yello_arrow{
          margin-left: 5px;
        }
      }
    }
  }

  .courses-list-section {
    .basic-button-component {
      text-align: center;
    }
    .large-device-course-section {
      margin: 40px 0;
      padding: 0 80px;
    }
    .row.search_Courses_results > .columns:first-child {
      padding-left: 0;
    }
    .row.search_Courses_results > .columns:last-child {
      padding-right: 0;
    }
    .CourseCard{
      margin-bottom: 24px;
    }
    .small-device-course-section{
      display: none;
      margin: 40px 0;
    }
  }

  .blog-list-section {
    .load-more-btn-section {
      text-align: center;
    }
    .blog-list-content-section {
      margin: 40px 0;
      padding: 0 80px;
    }
    .row.search_blog_results > .columns:first-child {
      padding-left: 0;
    }
    .row.search_blog_results > .columns:last-child {
      padding-right: 0;
    }
  }

  .blog-card-section {
    .blogCard{
      margin-bottom: 24px;
      .blog-img {
        width: 100%;
      }
      .blog-date,.blog-read-time {
        @include heading6_bold;
        line-height: 19px;
        color: rgba(78, 78, 78, 0.5);
        margin-top: 16px;
        margin-bottom: 0;
      }
      .blog-question {
        @include heading2;
        font-size: 28px;
        color: #4E4E4E;
        margin: 8px 0;
      }
      .blog-answer {
        @include heading6_medium;
      }
      &.small-device-blog-card{
        display: none;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin: 12px 0;
        .image-section{
          width: 40%;
          height: 100%;
          .blog-img{
            width: 40%;
            height: 100%;
          }
        }
        .content-section{
          width: 60%;
          padding: 8px;
          .blog-date-section{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

.custom-checkbox-component{
  width: 100%;
  .custom-control{
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
    &.overflow-checkbox{
      .overflow-control-input{
        display: none;
        &:checked~.overflow-control-indicator{
          &::after{
            transform: rotateZ(45deg) scale(1);
            top: -6px;
            left: 5px;
          }

          &::before{
            opacity: 1;
          }
        }
      }
      .overflow-control-indicator{
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1.5px solid rgba(233, 181, 28, 0.75);
        border-radius: 2px;


        &::after{
          content: '';
          display: block;
          position: absolute;
          //width: 16px;
          height: 16px;
          transition: .3s;
          transform: rotateZ(90deg) scale(0);
          width: 10px;
          border-bottom: 4px solid rgba(233, 181, 28, 0.75);
          border-right: 4px solid rgba(233, 181, 28, 0.75);
          border-radius: 3px;
          top: -2px;
          left: 2px;
        }

        &::before{
          content: '';
          display: block;
          position: absolute;
          //width: 16px;
          height: 16px;
          transition: .3s;
          width: 10px;
          border-right: 7px solid #fff;
          border-radius: 3px;
          transform: rotateZ(45deg) scale(1);
          top: -4px;
          left: 5px;
          opacity: 0;
        }
      }
      .overflow-control-description{
        font-family: "GilroyMedium",sans-serif;
        font-size: 14px;
        line-height: 115%;
        color: $vampire_grey;
        margin-left: 16px;
      }
    }
  }

}

.price-attribute-section{
  .price-attribute-feild-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    margin-top: 24px;
    .price-feild{
      padding-left: 12px;
      background-image: url("../../assets/images/price-symbol.svg");
      background-repeat: no-repeat;
      background-position: center left 0;
      background-size: 12px 12px;
      font-family: "GilroySemoBold",sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 170.1%;
      color: rgba(78, 78, 78, 0.75);
      border: 1px solid #E9B51C;
      border-radius: 4px;
      width: 72px;
      height: 35px;
      &:focus{
        outline: none;
      }
    }
    .to{
      font-family: "GilroySemoBold",sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 170.1%;
      color: rgba(78, 78, 78, 0.75);
    }
  }
}

@media screen and (max-width: 600px) {
  .slick-slider{
    overflow: hidden;
  }

  .course-list-component .blog-list-section .row.search_blog_results > .columns:first-child{
    padding-left:15px!important;
  }
  .course-list-component .blog-list-section .row.search_blog_results > .columns:last-child{
    padding-right:15px!important;
  }
  .course-list-component .blog-card-section .blogCard.small-device-blog-card .image-section {
    height: auto;
    .blog-img{
      width: 100%;
    }
  }
  .button-section{
    .flat-button-component button span{
      border-bottom: 2px solid #58A882!important;
    }
  }
  .flat-button-component button span{
    border-bottom: 2px solid #E9B51C;
  }


  .course-filter-component {
    margin: 24px 0;
    .filter-input-section {
      .filter_button{
        button {
          background: none !important;
          width: 56px !important;
          .filter_bitton_img {
            padding: 0 !important;
          }
        }
      }
      .filter_input{
        padding: 16px 80px !important;
        font-size: 14px !important;
        line-height: 170.1% !important;
      }
      .search_icon {
        right: 20px !important;
      }
    }
    #course-filter-modal{
      .modal-full-height {
        margin: 0;
        height: 100%;
      }
      .modal-content{
        height:100%;
      }
      .modal-body {
        padding: 0 !important;
      }
      .small-device-filter-attributes{
        display: block !important;
      }
      .large-device-filter-attributes, .modal-close-btn{
        display: none !important;
      }

    }

  }
  .professor-list-section {
    .ourExperts {
      padding: 0 !important;
    }
  }
  .search-result-title-section{
    .result-title{
      font-size: 16px !important;
      line-height: 115% !important;
      flex: 1;
      padding: 0 !important;
    }
    .view-all-btn {
      display: block !important;
    }
  }
  .courses-list-section {
    .search_Courses_results {
      padding: 0 !important;
    }
    .small-device-course-section{
      display: block !important;
    }
    .large-device-course-section{
      display: none;
    }
    .CourseCard {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .blog-list-section {
    .load-more-btn-section {
      display: none;
    }
    .blog-list-content-section {
      padding: 0 24px !important;
    }
    .row.search_blog_results > .columns:first-child {
      padding-left: initial !important;
    }
    .row.search_blog_results > .columns:last-child {
      padding-right: initial !important;
    }
  }
  .blog-card-section {
    .blogCard{
      &.small-device-blog-card{
        display: flex !important;
      }
      &.large-device-blog-card{
        display: none;
      }
      .blog-question{
        font-size: 12px!important;
      }
      .blog-date{
        font-size: 12px!important;
      }
      .blog-read-time{
        font-size: 12px!important;
      }
    }
  }
}
