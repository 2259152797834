@import "../../assets/common/Typography";
@import "../../assets/common/Colors";

.professor-card-component {
  margin-bottom: 33px;
  .instructor-image-section {
    // position: relative;
    // top: 0;
    // left: 0;
    // padding-top: 1rem;
    width: 200px;
    height: 200px;
    overflow: hidden;
    background: url("../../assets/Illustrations/Polygon-Light.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    position: relative;
    margin: 0 auto;
    // .instructor-bg {
    //   position: relative;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    // }

    .instructor-profile {
      width: 90%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .instructor-detail-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .professor-name {
      @include paragraph1();
      font-family: "GilroyBold", sans-serif;
      color: #000000;
      margin: 12px 0;
      text-align: center;
    }

    .role, .city {
      @include paragraph2_regular();
      color: $vampire_grey;
      margin-bottom: 12px;
      text-align: center;
    }
  }
  .shadow{
    background: rgba(60, 60, 60, 0.44);
    filter: blur(22.7996px);
    width: 100%;
    height: 20px;
    position: absolute;
  }
}

@media screen and (max-width: 600px){
  .professor-card-component{
    margin: 33px 0;
    .instructor-image-section{
      // padding-top: 0;
      .instructor-profile {
        // top: -10px;
      }
    }
  }
}
