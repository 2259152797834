@import "../../../assets/common/Typography";
@import "../../../assets/common/Colors";

.MyCourses {

  .time-of-courses-completed{
    padding-top: 8px;
  }

  .cusdataerror {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular",sans-serif;
    color: $vampire_grey;
  }

  .sub_heading {
    @include heading2;
    font-size: 32px;
    color: #4e4e4e;
    //margin-top: 40px;
    margin-bottom: 0;
  }

  .card1 {
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 44px 25px 25px 25px;
  }

  .card_head {
    @include heading2;
    font-size: 24px;
    //margin-top: 19px;
    color: #4e4e4e;
  }

  .card_header {
    @include heading2;
    font-size: 16px;
    font-weight: 600;
    color: #8F8F8F;
  }

  .card_head_part {
    @include heading2;
    font-size: 16px;
    margin-bottom: 10px;
    color: black;
  }

  .Progressbar {
    border-radius: 8px;
    height: 11px;
    width: 70%;
  }

  .Progressbar::-webkit-progress-bar {
    background-color: #E4E4E4;
    border-radius: 8px;
  }

  .Progressbar::-webkit-progress-value {
    background-color: #389652;
    border-radius: 8px;
  }

  .hours_text {
    @include heading2;
    font-size: 32px;
    color: #348B61;
  }

  .block_break {
    margin: 40px 0;
  }

  .clock, .note1 {
    padding-right: 13px;
  }

  .block_text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;
  }

  .particular_sector {
    background-color: #E8FFF4;
    border-radius: 12px;
    padding: 14px 22px;
  }

  .particular_sectors {
    background-color: #FFF4F4;
    border-radius: 12px;
    padding: 14px;
  }

  .hours_text1 {
    @include heading2;
    font-size: 32px;
    color: #A34848;
  }

  .hour_subtext {
    @include heading2;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #8F8F8F;
  }


  .container_1 {
    .rounded-button-component {
      margin-top: 16px;

      .btn {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }


  .CompletedCourses {
    .CompletedCourses_card {
      padding: 24px;
      margin-bottom: 24px;
    }

    .rounded-button-component button {
      background-color: #58A882;
    }

    .sub_heading {
      margin-bottom: 40px;
    }

    .card_head {
      //margin-top: 0;
    }
  }




}

@media screen and (max-width: 600px){
  .MyCourses{
    padding: 0;
    .sub_heading {
      //margin-top: 32px;
      margin-bottom: 0;
    }

    .customised_container {
      display: block !important;
    }
    .card1 {
      padding: 40px 24px 24px 24px;
    }

    .particular_sector {
      margin-bottom: 16px;
      margin-top: 24px;
    }
    .hour_subtext {
      text-align: center;
      font-size: 14px;
    }
    .sub_heading, .hours_text, .hours_text1 {
      font-size: 24px;
    }
    //.container {
    //  padding: 24px !important;
    //  margin: 24px 0 !important;
    //}
    .container_1 {
      .rounded-button-component {
        margin-top: 32px;
        margin-bottom: 8px;

        button {
          margin-left: 0;

        }
      }
    }
    .mar_0 {
      .rounded-button-component button {
        margin: 24px 0;
      }
    }
    .card_head {
      //margin-top: 16px;
      margin-bottom: 22px;
      line-height: 120%;
    }
    .card_header {
      margin-bottom: 14px;
    }
    .CompletedCourses {
      .header {
        .sub_heading {
          margin-top: 56px;
          margin-bottom: 36px;
        }
      }
    }
    .Progressbar{
      width: 100%;
    }
  }
}
