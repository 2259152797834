@mixin Gilroy-16p-500w-175_3 {
  font-family: "GilroyMedium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 175.3%;
  color: #4e4e4e;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

.forget-password{
  margin: 100px 0!important;
  .phone{
    background: #ffffff;
    border: 0.5px solid #e9b51c;
    border-radius: 8px;
    outline-color: #e9b51c;
    width: 100%;
    padding: 13px 14px 14px 14px;
  }
  .redStar {
    margin-top: -10px;
    padding: 2px;
  }
  .form-label{
    margin-bottom: 16px;
    @include Gilroy-16p-500w-175_3;
    line-height: 115%;
  }
  .form-feild-item{
    margin-bottom: 24px;
  }
  .conf_phone {
    position: relative;
    .w-62{
      min-height: 62%!important;
    }
    .conf-btn {
      @include Gilroy-16p-500w-175_3;
      font-weight: bold;
      line-height: 18px;
      background-color: rgba(233, 181, 28, 1);
      width: 15%;
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      min-height: 100%;
      border-radius: 0px 8px 8px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    .yellow_crt_btn1 {
      position: absolute;
      right: 10px;
      top: 5px;
      width: 43px;
      height: 43px;
    }
  }
  .conf-btn {
    @include Gilroy-16p-500w-175_3;
    font-weight: bold;
    line-height: 18px;
    background-color: rgba(233, 181, 28, 1);
    width: 15%;
    position: absolute;
    right: 0;
    top: 0;
    min-height: 100%;
    border-radius: 0px 8px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  button {
    outline: none;
    border: none;
  }
  .custome-opt-container{
    margin-bottom: 40px;
    .opt-input-field{
      width: 70%;
      margin-right: 40px;
      height: 50px;
    }
    .to_login{
      white-space: nowrap;
      color: #E9B51C;
      font-family: "GilroyBold",sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 175.3%;
    }
  }
  .mobile-top{
    margin-top: 40px;
  }
  .custome-enter-btn{
    width: 100%;
    .btn{
      width: 100%;
    }
  }
}





  .ds-pb-16{
    padding-bottom: 16px;
  }
  .redStar {
    margin-top: -10px;
    padding: 2px;
  }
  .error-message-email{
    color: red;
    margin: -15px 0 15px 0;
  }
  .error-message-password{
    color: red;
    margin: 5px 0;
  }
  .main {
    width: 80%;
    position: relative;
  }
  .closebutton {
    position: absolute;
    top: -60px;
    right: -88px;
    cursor: pointer;
    width: 56px;
    //height: 56px;
  }
  .w-80 {
    width: 80%;
  }
  .email, .password {
    padding: 14px 12px 13px 14px;
  }

  .password {
    padding-bottom: 12px;
  }

  .button {
    padding: 10px;
  }

  .check {
    margin-top: 8px
  }

  .tosing {
    text-align: center;
    margin-top: 40px;
  }

  .inner-side {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-family: "GilroyBold", sans-serif;
      font-weight: bold;
      font-size: 36px;
      line-height: 175.3%;
      color: #FFFFFF;
    }
  }

  .Logo {
    max-width: 126px;
    max-height: 120px;
  }

  .mbg-w {
    background-color: #ffffff;
    border-radius: 16px;
  }

  .side-logo {
    background-color: rgba(233, 181, 28, 1);
    vertical-align: middle;
    background-image: url("../../assets/images/logo_bg.png");
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 175.3%;
    color: #ffffff;
    border-radius: 16px;
  }

  .content {
    margin: auto;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 49px;

    .title {
      font-family: "GilroyBold", sans-serif;
      font-size: 24px;
      line-height: 175.3%;
      color: #4e4e4e;
      //margin-bottom: 32px !important;
    }

    .lable {
      font-family: "GilroySemoBold", sans-serif;
      font-size: 16px;
      line-height: 115%;
      color: #4E4E4E;
    }

    input,
    input:focus-visible,
    input[type="checkbox"],
    input[type="checkbox" i] {
      background: #ffffff;
      border: 0.5px solid #e9b51c;
      border-radius: 8px;
      outline-color: #e9b51c;
      width: 100%;
      font-family: "GilroyRegular", sans-serif;
      font-size: 12px;
      line-height: 175.3%;
      color: #4E4E4E;
      opacity: 0.5;
    }

    .box {
      width: 24px;
      min-height: 24px;
      border: 3px solid #E9B51C;
      margin-top: 14px;
    }

    .forget {
      float: right;
      font-family: "GilroySemoBold", sans-serif;
      font-size: 12px;
      line-height: 175.3%;
      text-align: center;
      text-decoration-line: underline;
      color: #e9b51c;
      padding-top: 12px;
      cursor: pointer;
    }

    .button {
      background: #e9b51c;
      border: 0.5px solid #e9b51c;
      border-radius: 8px;
      font-family: "Gilroy", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 115%;
      color: #ffffff;
    }

    .check {
      font-family: "GilroySemoBold", sans-serif;
      font-size: 16px;
      line-height: 115%;
      color: #4e4e4e;
      display: flex;
      align-items: center;
    }

    .yellow_crt_btn {
      width: 24px;
      min-height: 24px;
      margin-right: 16px;
    }
  }

  .or {
    display: none;
  }

  .tosing {
    font-family: "GilroySemoBold", sans-serif;
    font-size: 16px;
    line-height: 175.3%;
    color: #4e4e4e;
    margin-bottom: 56px !important;




    .sapn-sing {
      font-family: "GilroyBold", sans-serif;
      font-size: 16px;
      line-height: 175.3%;
      text-decoration-line: underline;
      color: #e9b51c;
      padding-left: 8px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  .login {
    .closebutton {
      width: 35px;
      min-height: 35px;
      top: -15px;
      right: -30px;
      z-index: 2;
      border-radius: 29px;
    }
    .ds-pb-16{
      padding-bottom: 0;
    }

    .email, .password {
      margin-top: 7.82px !important;
    }

    .inner-side {
      flex-direction: row-reverse;
      min-height: 101px;
      align-items: center;
      span{
        font-size: 24px;
        line-height: 140%;
      }
    }

    .Logo {
      max-width: 64px;
      max-height: 61px;
    }

    .side-logo {
      font-size: 24px;
      line-height: 140%;
      height: 101px;
    }


    .lable-Email {
      padding: 0 !important;
    }

    .content {
      padding: 24px 16px;
      .lable{
        font-size: 14px;
      }
      .title {
        display: none;
      }

      input,
      input:focus-visible,
      input[type="checkbox"],
      input[type="checkbox" i] {
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 170.1%;
        color: #4E4E4E;
        opacity: 0.5;
      }

      .box {
        width: 16px;
        min-height: 16px;
        border: 3px solid #E9B51C;
        margin-top: 14px;
      }

      .forget {
        padding-top: 8px;
      }

      .button {
        margin-top: 8px !important;
        font-size: 16px;
      }

      .check {
        margin-top: 12px !important;
        font-size: 14px;
        line-height: 170.1%;
      }

      .yellow_crt_btn {
        width: 16px;
        min-height: 16px;
        margin-top:0;
      }
    }

    .or {
      display: block;
      font-family: 'GilroyMedium', sans-serif;
      font-size: 24px;
      line-height: 175.3%;
      color: #4E4E4E;
      opacity: 0.5;
      text-align: center;
    }

    .tosing {
      font-family: "GilroyRegular",sans-serif;
      margin-top: 20px;
      margin-bottom: 0!important;
      font-size: 12px;
      .sapn-sing{
        font-size: 14px!important;
      }
    }
  }
}


@media screen and (max-height: 800px) {
  .login{
    .main{
      .closebutton{
        width: 35px;
        top: -38px;
        right: -55px;
      }
    }
    .content{
      padding-top: 20px;
      .title{
        margin-bottom: 0!important;
        font-size: 19px;
      }
      .button{
        padding: 7px;
        font-size: 19px;
      }
    }
    .tosing{
      margin-bottom: 40px!important;
      margin-top: 40px;
    }
  }
}