@import "../../assets/common/Typography";

$font-color:#4E4E4E;
@mixin small-header{
  font-family: 'GilroyMedium',sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  font-size: 24px;
  line-height: 29px;
  color: #4E4E4E;
}
.Consultation{
  .error-message{
    color: red;
    margin: 5px 0;
  }
  .header{
    background-image: url("../../assets/images/bg_lines.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    text-align: center;
  }
  .header-font{
    @include paragraph2_bold;
    font-size: 48px;
    color:$font-color;
  }
  .main-container{
padding: 64px 0 80px 0;
  }
  .para{
@include paragraph2_medium;
    color:$font-color;
    line-height: 170%;
    margin-top: 8px;
    margin-bottom: 40px;
  }
  .hr_tag{
    border-top: 1px solid #E9B51C;
    margin-bottom: 40px;
  }
  .small_header{
@include small-header;
    margin-bottom: 56px;
  }
  .email{
    @include small-header;
    line-height: 28px;
    color: #E9B51C;
    font-weight: 500;
    margin-bottom: 48px;
  }
.social-buttons{
  @include small-header;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 24px;
}
  .rdt{
    .form-control{
     background-color: transparent!important;
    }
    input{
      height: 52px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background-image: url("../../assets/images/yellow_down_arrow.png");
      background-repeat: no-repeat;
      background-position: 90% center;

      &:focus{
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
      }
      &:focus-visible{
        outline: none;
      }
    }
  }
input,textarea,select,.rdtPicker{
  outline-color: #E9B51C;
}
  .right-side{
    border: 1px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 40px;
    .small_header{
      line-height: 175.3%;

    }
  }
  .redStar{
    margin-top: -15px;
  }

  .form-field{
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .btn{
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    border-radius: 5px;
  }
  .top-form-field{
    gap: 24px;
  }
  .form-field{
    width: 100%;
  }
  .flex1{
    position: relative;
    width: 100%;
  }
  .Create{
    @include paragraph2_medium;
    line-height: 115%;
    white-space: nowrap;
    position: absolute;
    background-color: white;
    left: 20px;
    top:9px;
    z-index: 2;
  }
  .Create1,.form-check-label{
    @include paragraph2_medium;
    line-height: 115%;
    white-space: nowrap;
    background-color: white;
  }
  .Create1{
    margin-top: 10px;
  }
  input,textarea,select{
    border: 0.5px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 14px;
    margin-top: 16px;
    margin-bottom: 24px;
    background: white;
  }
  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    outline: #ffa500 solid 1px;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    outline: #ffa500 solid 1px;
  }
  select{
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("../../assets/images/yellow_down_arrow.png");
    background-repeat: no-repeat;
    background-position: 90% center;
  }
  .form-check-input{
    margin: 12px;
  }
  textarea{
    height: 154px;
    margin-top: 16px;
    margin-bottom: 40px;
  }
  .inquiries{
    margin-bottom: 12px;
  }
.follow{
  margin-bottom: 40px;
}
  @media only screen and (max-width: 1100px) {
.Create,.Create1,.form-check-label{
  font-size: 14px;
}
  }
  @media only screen and (max-width: 1000px) {
    .top-form-field {
      display: block !important;
    }
  }
  @media only screen and (max-width: 720px) {
    .header{
      background-image: url("../../assets/images/bg_lines.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 13px 0;
      text-align: center;
    }
    .email{
      margin-bottom: 40px;
    }
  .header-font{
    font-size: 24px;
  }
    .main-container{
      padding: 44px 0 80px 0;
      margin: 0 1px;
    }
    .right-side{
      padding: 24px 16px;
      padding-bottom: 8px;
      .small_header{
        font-size: 16px;
        line-height: 115%;
        margin-bottom: 26px;
      }
    }
    .Create{
      font-size: 14px;
      line-height: 170%;
      font-weight: 500;
    }
    .btn{
      margin: 16px 0;
    }
    input,select,textarea{
      margin-top:20px;
      margin-bottom: 24px;
    }
    .title{
      margin-top: 80px;
      margin-bottom: 16px;
    }
    .para{
      margin-bottom: 24px;
    }
    .inquiries{
      margin-bottom: 24px;
    }
    .follow{
      margin-top: 43px;
      margin-bottom: 6px;
    }
    .social-buttons{
      margin: 25px 0;
    }
    .span-mail{
      color: #4E4E4E;
      padding-right: 16px;
    }
    .follow{
      margin-top: 0;
      margin-bottom: 32px;
    }
  }
}
