@import "../../assets/common/Colors";
@import "../../assets/common/Typography";

.course-accordian-component{
  .accordion {
    border: 1px solid #E9B51C;
    border-radius: 2px;
    .accordion__item + .accordion__item {
      border-top: 1px solid #E9B51C;
    }
    .accordion__button {
      background-color: #FCF4DF;
      cursor: pointer;
      padding: 22px 24px;
      width: 100%;
      border: none;
    }
    .accordion__button[aria-expanded='true'] .accordian-arrow,
    .accordion__button[aria-selected='true'] .accordian-arrow {
      transform: rotate(180deg);
    }

    [hidden] {
      display: none;
    }
    .accordion__panel {
      padding: 27px 29px;
      animation: fadein 0.35s ease-in;

    }
  }
  .lesson-item-component{
    cursor: pointer;
    .left-section{
      gap: 28px;
      cursor: pointer;
      .lesson-name{
        color: $vampire_grey;
        font-family: "GilroyRegular", sans-serif;
        font-size: 24px;
      }
      .active{
        color: #e9b51c;
      }
    }
    .right-section{
      gap: 24px;
      cursor: pointer;
      .preview{
        color: $squash;
        text-decoration-line: underline;
        @include heading6();
        font-family: "GilroyRegular", sans-serif;
        cursor: pointer;
      }
    }
    &:not(:last-child){
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(233, 181, 28, 0.3);
    }
    &:not(:first-child){
      padding-top: 16px;
      border-bottom: 1px solid rgba(233, 181, 28, 0.3);
    }
    .paid-right-section{
      // width: 30%;
      text-align: end;
      .lesson-completed{
        color: #067B27;
        text-decoration-line: underline;
        @include heading6();
        font-family: "GilroyRegular", sans-serif;
        cursor: pointer;
      }
      .lesson-watchnow,.lesson-readenow{
        color: #E9B51C;
        text-decoration-line: underline;
        @include heading6();
        font-family: "GilroyRegular", sans-serif;
        cursor: pointer;
      }
      .resume{
        background-color: #e9b51c;
        color: #fff;
        border-radius: 12px;
        padding: 6px;
        box-shadow: none;
        width: fit-content;
        white-space: nowrap;
        @include heading6();
      }
    }
  }
  .lesson-heading-component{
    .small-device-heading{
      display: none;
    }
    .large-device-heading{
      display: flex;
    }
    .left-section{
      gap: 24px;
      .module-name{
        color:$vampire_grey;
        @include heading5();
        margin-bottom: 0;
      }
    }
    .duration{
      color:$vampire_grey;
      @include heading6();
      margin-bottom: 0;
      font-family: "GilroyRegular",sans-serif;
    }
    .duration-section{
      width: 30%;
      text-align: center;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

}

@media screen and (max-width: 600px) {
  .course-accordian-component {
    .lesson-heading-component {
      .small-device-heading {
        display: flex;
      }
      .large-device-heading{
        display: none;
      }
      .left-section{
        flex-direction: column;
        gap: 3px;
        .module-name{
          @include heading6_bold();
        }
      }
    }
    .lesson-item-component {
      .left-section {
        .lesson-name {
          font-size: 14px;
        }
      }
      .right-section {
        flex-direction: column;
        gap: 0;
        .preview{
          font-size: 14px;
          order: 1;
        }
      }
    }
  }
}


