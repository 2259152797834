@import "../../assets/common/Typography.scss";

.CoursePage {
  .video-player-component,
  .video-react-controls-enabled, .video-react-poster {
    //height: 695px;
    @media only screen and (max-width: 600px) {
      height: auto;
    }
  }

  .video1 {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .AboutCourse {
    width: 50%;

  }

  .About {
    background-image: url("../../assets/images/instructor-card-bg.png");

    .mainTitle {
      @include heading1();
      color: rgba(78, 78, 78, 1);
      padding: 26px 0;
      margin: 0;
      width: 60%;
      @media only screen and (max-width: 1200px) {
        font-size: 38px;
      }
      //@media only screen and (max-width: 1300px) {
      //  font-size: 38px;
      //
      //}
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .about-course-component {
      padding-bottom: 26px;

      .title {
        padding-top: 18px;
      }

      .description-wrapper {
        width: 60%;
      }
    }

    @media only screen and (max-width: 600px) {
      .title {
        padding-top: 47px !important;
        margin-bottom: 37px !important;
      }
      .description-wrapper {
        width: 100% !important;
      }
    }
    @media only screen and (max-width: 768px) {
      .description-wrapper {
        width: 100% !important;
      }
    }
    @media only screen and (max-width: 600px) {
      background: none;
    }
  }

  .OurStudentsWords {
    @media only screen and (max-width: 600px) {
      display: none;
    }

    .title {
      @include heading3();
      color: rgba(78, 78, 78, 1);
      padding-top: 98px;
      @media only screen and (max-width: 1200px) {
        font-size: 38px;
      }
    }

    .card {
      background: #ffffff;
      box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }

    .card {
      position: relative;
      width: 50%;
      padding: 30px 17px 41px 40px;
    }

    .Group19880 {
      position: absolute;
      right: 40px;
      top: 34px;
    }

    .leftrightArrow {
      margin-top: 44px;
      margin-bottom: 44px;
    }

    .span-studentName {
      padding: 8px 16px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }

    .studentWords {
      padding-top: 16px;
      color: #9d9d9d;
    }

    .nextstudentbtn {
      width: 88px;
      height: 88px;
      padding: 33px;
      border: 1px dashed #e9b51c;
      border-radius: 50%;
      margin-right: 30px;
    }

    .left-Arrow,
    .Rigth-Arrow {
      width: 22px;
    }
  }

  .RelatedCourses {
    width: 60%;
    margin-top: 40px;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 16px;
    }

    .main-title {
      @media only screen and (max-width: 600px) {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .title {
      @include heading3();
      color: rgba(78, 78, 78, 1);
      @media only screen and (max-width: 600px) {
        font-size: 24px;

        margin: 48px 0;
      }
    }

    .rounded-button-component {
      min-width: 144px;
      align-self: center;
      @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: end;
      }
    }

    .row {
      @media only screen and (max-width: 600px) {
        flex-wrap: nowrap !important;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;


      }

      .row::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .col-6 {
      @media only screen and (max-width: 600px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }

    .over-hid {
      @media only screen and (max-width: 600px) {
        overflow: hidden;
      }
    }

    .CourseCard {
      position: relative;
      background: #ffffff;
      box-shadow: 16.8987px 16.8987px 25.3481px rgba(0, 0, 0, 0.05);
      border-radius: 6.75949px;
      margin-bottom: 48px;
    }

    //.bg {
    //  background-image: url("../../assets/images/coursecard-bg.png");
    //  background-repeat: no-repeat;
    //  background-size: cover;
    //  position: relative;
    //  height: 147.37px;
    //  border-radius: 6.75949px;
    //}

    //.star1 {
    //  position: absolute;
    //  background: rgba(0, 0, 0, 0.21);
    //  border-radius: 6.75949px;
    //  width: 75px;
    //  text-align: center;
    //
    //  .Star {
    //    padding-right: 12px;
    //  }
    //
    //  color: white;
    //}

    //.heart1 {
    //  position: absolute;
    //  right: 8px;
    //  top: 8px;
    //  background: rgba(0, 0, 0, 0.21);
    //  border-radius: 6.75949px;
    //  width: 35px;
    //  text-align: center;
    //  cursor: pointer;
    //}

    //.courseName {
    //  font-family: "GilroyBold", sans-serif;
    //  font-size: 13px;
    //  line-height: 16px;
    //  color: #4e4e4e;
    //  padding-top: 18px;
    //  padding-left: 21px;
    //}

    //.smallUser {
    //  padding-right: 7px;
    //  padding-left: 16px;
    //  margin-top: -2px;
    //}

    //.parentUser {
    //  padding-top: 10px;
    //}

    //.RateTage {
    //  font-family: "GilroyBold", sans-serif;
    //  font-size: 13px;
    //  line-height: 16px;
    //  color: #4e4e4e;
    //  padding-left: 18px;
    //  padding-top: 7px;
    //  padding-bottom: 10px;
    //}

    //.realRate {
    //  line-height: 120%;
    //  color: #787a91;
    //  opacity: 0.8;
    //  padding-left: 16px;
    //  padding-right: 11px;
    //}

    //.tag {
    //  background-color: rgba(6, 123, 39, 1);
    //  color: white;
    //  padding: 2px 9px;
    //  border-radius: 3.37975px;
    //}

    //.cart {
    //  position: absolute;
    //  right: 18px;
    //  bottom: 18px;
    //
    //  //background: #e9b51c;
    //  border-radius: 6.75949px;
    //  width: 40px;
    //  height: 40px;
    //  text-align: center;
    //  padding-top: 9px;
    //}
  }

  .fixed1 {
    position: fixed;
    background: fixed;
    top: 5rem;
    right: 84px;
    background-color: white;
    z-index: 1;
    height: 100%;
    @media only screen and (max-width: 600px) {
      position: relative;
      background: none;
      top: auto;
      right: auto;
    }
    @media only screen and (max-width: 1300px) {
      //right: 54px;
      right: 1% !important;
    }

    @media only screen and (max-width: 1400px) {
      //right: 54px;
      right: 3%;
    }
    @media only screen and (max-width: 600px) {
      right: 0;
      left: 0;
    }
  }

  .first-section {

    @media only screen and (max-width: 1400px) {
      //max-width: 500px;
    }
    @media only screen and (max-width: 1200px) {
      //max-width: 400px;
      //padding-left: 40px;
      //padding-right: 40px;
    }
  }

  .mobilAddCart {
    display: none !important;
    z-index: 1;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      display: flex !important;
      //.cart{
      //  width: 100%;
      //}
      position: fixed;
      bottom: 0;
      background: white;
      width: 100%;
    }

    .name {
      width: 50%;
      text-align: center;
      background-color: rgba(78, 78, 78, 1);
      color: white;

      .in-name {
        padding-top: 9px;
      }
    }

    .cart {
      width: 100%;
      background: #e9b51c;
      display: flex;
      justify-content: center;
      color: white;
    }

    .cart > p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .CourseAccordian1 {
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 100%;

    }


    .title {
      font-size: 48px;
      font-family: "GilroyBold", sans-serif;
      color: #4E4E4E;
      padding-top: 36px;
      justify-content: space-between;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
        flex-direction: column;
      }

      @media only screen and (min-width:600px) and (max-width: 1024px) {
        font-size: 34px;
      }

      .para {
        @include heading6();
        font-family: "GilroyRegular", sans-serif;
        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
    }

    .button1 {

      @media only screen and (max-width: 600px) {
        align-self: left;
        margin-bottom: 48px;
      }
    }
  }
  .course-preview-card-component .course-instructor-section{
    padding: 0;
  }
  .course-preview-card-component .first-section .course-rating-circle{
    padding: 12px 0;
  }
  .price-card-component .original-price{
    font-size: 65px;
  }
}

.mobile-add-to-cart{
  .modal{
    .modal-dialog{
      .modal-content{
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  @media(max-width:768px){
    .modal{
      .modal-dialog{
        .modal-content{
          .modal-body{
            padding: 0;
          }
        }
      }
    }
  }
}
