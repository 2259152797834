@import "../../../assets/common/Typography";

.ReferalsRedeem{
  .invite {
    background: url("../../../assets/images/bg_lines.png");
    padding: 40px 0;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 600px) {
      background: url("../../../assets/images/small_bg.png");
    }
    @media only screen and (max-width: 600px) {
      margin-right: -24px;
      margin-left: -24px;
      padding-top: 14px;
      padding-bottom: 31px;
    }

    .title {
      @include heading1;
      color: #4E4E4E;
      padding-bottom: 10px;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 120%;
        padding-bottom: 8px;
      }
    }

    .para {
      @include paragraph2_medium;
      @include paragraph1;
      width: 60%;
      color: #4E4E4E;
      line-height: 120%;
      padding-bottom: 40px;
      @media only screen and (max-width: 600px) {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 16px;
      }
    }

    .invitebg {
      float: right;
      width: 40%;
      max-height: 250px;
      max-width: 512px;
      margin-top: -80px;
      @media only screen and (max-width: 600px) {
        float: none;
        margin-top: 0px;
        width: 120px;
        margin-bottom: 16px;
      }
    }

    .Email {
      background: #FFFFFF;
      box-shadow: 0px 1px 10px rgba(78, 78, 78, 0.15);
      border-radius: 8px;
      padding: 19px 0 18px 24px;
      width: 100%;
      border: none;
    }

    .par-email {
      position: relative;
      width: 60%;
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .send {
      position: absolute;
      border-bottom-right-radius: 8px;
      right: 0;
      height: 100%;
      width: 144px;
      border-top-right-radius: 8px;
      color: white;
      background: #E9B51C;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      @media only screen and (max-width: 600px) {
        width: 80px;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .m-24 {
      margin-top: 24px;
      @media only screen and (max-width: 600px) {
        margin-top: 16px;
      }
    }
  }
  .ReferalsRedeemTabs{
    background-color: #4E4E4E;
    padding: 0 80px;
  }
  .main_flex{
    @include heading2;
    gap: 240px;
  }
  .enable{
    font-size: 24px;
    color:#FFFFFF;
    line-height: 120%;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
  }
  .disable{
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
  }
  .border_marker{
    hr{
      position: absolute;
      border-top: 8px solid #E9B51C;
      margin: 0;
      bottom: -19px;
      width: 100%;
    }
  }
  .hide_mark{
    display: none;
  }
  .ReferralsCode{
    .sub_num{
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #4E4E4E;
    }
    .sub_text{
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #4E4E4E;
    }
      .linear_colors{
      position: relative;
      background: rgb(78,78,78);
      background: linear-gradient(0deg, rgba(78,78,78,1) 0%, rgba(54,179,212,1) 35%, rgba(38,149,178,1) 100%);
      border-bottom-left-radius: 20px;
      /* border-bottom-left-radius: 20px !important; */
      border-bottom-right-radius: 20px;
    }
    .left_half_circle{
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -25px;
    }
    .right_half_circle{
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -25px;
    }

    .code{
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      line-height: 30px;
      color:#4E4E4E;
      margin: 0;
    }
    .card_parts{
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: 1px dashed #4E4E4E !important;
    }
    .carder_texts{
      @include heading2;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      line-height: 30px;
      color:#FFFFFF;
      text-align: center;
      margin-top: 16px;
    }
    .card_border{
      border-radius: 20px !important;
      justify-content: space-evenly !important;
      margin-bottom: 5%;
      margin-right: 5%;
      max-width: 400px;
      margin-top: 100px;
    }
    .higher_section{
      border: 1px dashed #FFFFFF;
      position: relative;
      margin: 25px 0;
    }

    .gift_image{
      max-width: 80px;
    }
    .section_flex {
      display: flex;
      justify-content: center;
      margin: 70px 0;
      align-items: end;
    }
  }
  .PendingReferrals{
    .RFS-StepperContainer .RFS-StepContainer .RFS-StepMain .RFS-StepButton-d0.completed , .RFS-StepButton-d2,.RFS-StepButton-d4{
      background-image: url("../../../assets/images/green_crt_btn.png");
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
      margin-top: -3px;
      .RFS-StepButtonContent{
        display: none;
      }
    }
    .RFS-StepperContainer .RFS-StepContainer .RFS-StepMain .RFS-StepButton-d6{
      background: rgba(88, 168, 130, 0.6);
      background-repeat: no-repeat;
      background-size: cover;
      width: 24px;
      height: 24px;
      margin-top: 4px;
      .RFS-StepButtonContent{
        display: none;
      }
    }
    .RFS-StepperContainer .RFS-StepContainer .RFS-StepMain .RFS-StepButton-d8{
      background: rgba(88, 168, 130, 0.3);
      background-repeat: no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 8px;
      .RFS-StepButtonContent{
        display: none;
      }
    }
    .RFS-LabelContainer{
      display: none;
    }
    .RFS-Connector{
      border: 3px solid #58A882;
    }
    .RFS-ConnectorContainer-d6{
      top: calc((2em - 1px) / 2);
      left: calc((-50% + 2em) - 21px);
      right: calc((50% + 2em) - 25px);
      position: absolute;
      .RFS-Connector{
        border: 1px solid rgba(88, 168, 130, 0.3);
      }
    }
    .RFS-ConnectorContainer-d4{
      top: calc((2em - 1px) / 2);
      left: calc((-50% + 2em) - 19px);
      right: calc((50% + 2em) - 21px);
      position: absolute;
      .RFS-Connector{
        border: 1px solid rgba(88, 168, 130, 0.5);
      }
    }

  }
}
