
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
  url("./assets/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),
  url("./assets/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"),
  url("./assets/fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
  url("./assets/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "GilroyBlack";
  src: local("GilroyRegular"),
  url("./assets/fonts/Gilroy/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
}


@font-face {
  font-family: "InterLight";
  src: local("InterLight"),
  url("./assets/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
  url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
  url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "InterSemiBold";
  src: local("InterSemiBold"),
  url("./assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "RecoletaBold";
  src: local("RecoletaBold"),
  url("./assets/fonts/Recoleta/Recoleta-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
  url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}



#ct-container{
  z-index: 5000 !important;
}
._loading_overlay_wrapper{
  min-height: 100vh;
  height: 100% !important;
}

