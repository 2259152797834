@import "../../../assets/common/Typography";

.my-profile-component {
  .space-divider{
    display: none;
  }
  .error-message{
    color: red;
    margin: 8px 0 5px;
  }
  .uploadprofileimage {
    display: none;
  }

  .code {
    pointer-events: none;
  }
  .editable-input{
    pointer-events: auto;
    cursor: pointer;
  }

  .title {
    @include heading2;
    font-size: 36px;
    color: #4E4E4E;
  }

  input, select, {
    @include heading2;
    font-size: 16px;
    color: #4E4E4E;

    &:focus-visible {
      outline-color: #E9B51C;
    }
  }

  .top_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .input_field_top {
    border: 1px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 35px 3%;
    margin-top: 36px;
  }

  .sub_heading {
    @include heading2;
    font-weight: 600;
    font-size: 24px;
    line-height: 175.3%;
    color: rgba(78, 78, 78, 0.3);
  }

  .profile_photo {
    width: 63px;
    height: 63px;
    border-radius: 8px;
  }

  .col-12 {
    display: flex;
    padding: 0;
    align-items: baseline;
  }

  .w-40 {
    width: 35%;
  }

  .w-60 {
    width: 60%;
  }
  .input-feild-section{
    margin-bottom: 32px;
  }

  input, select {
    //margin-bottom: 32px;
    border: 0.5px solid #E9B51C;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("../../../assets/images/yellow_down_arrow.png");
    background-repeat: no-repeat;
    background-position: 90% center;
    background-color: white;
  }

  .rounded-button-component {

    .btn {
      white-space: nowrap;
    }
  }

  .m-0 {
    @include paragraph2_regular;
    font-weight: 500;
    line-height: 115%;
    color: #4e4e4e;
  }

  .img_profile {
    position: relative;
    display: flex;
    justify-content: start;
  }

  .Profile_head {
    align-items: center;
  }

  .change_button {
    position: absolute;
    bottom: 10px;
    background: rgba(0, 0, 0, 0.21);
    border-radius: 8px;
    height: 24px;
    width: 63px;
    text-align: center;
    cursor: pointer;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    //display: none;
    //-webkit-appearance: none;
    width: 15px;
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    color: yellow;
    background-image: url("../../../assets/images/date_bg.png");
  }

  .custom-inpt { // style class to adjust the Date Input control default padding value
    padding: 10px !important;
  }

  //    .sec_row{
  //      margin-top: 32px;
  //    }
  //.one_row{
  //  margin-top: 36px;
  //}
  .top_container {

    //margin-right: 1.8%;
  }

  .w-12 {
    width: 17.5%;
  }

  .w-87 {
    width: 80%;
  }

  @media only screen and (max-width: 900px) {
    //margin: 32px 24px 80px 24px;

    .profile_to {
      display: none;
    }
    .img_profile {
      position: relative;
      margin: 40px auto;
      display: flex;
      justify-content: center;
    }
    .sub_heading {
      font-size: 20px;
      font-weight: 600;
      color: #4E4E4E;
      margin: 0;
    }
    .input_field_top {
      margin-top: 0;
      padding: 24px 16px;
    }
    .sec_row {
      margin-top: 40px;

      .second_top_field {
        margin-right: 0;
      }

    }
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;
    }
    .w-40 {
      position: absolute;
      z-index: 2;
      background-color: white;
      padding: 2px;
      width: auto;
      top: -10px;
      left: 36px;
      color: #C1C1C1;
    }
    .w-60 {
      position: relative;
      width: 90%;
      margin-right: 5%;
      margin-left: 5%;
    }
    .max210 {
      max-width: none;
    }
    input, select {
      width: 100%;
      //margin-bottom: 36px;
    }
    .flat-button-component {
      button {
        color: #E9B51C;
      }

      .btn {
        margin-right: 0;
        padding-right: 0;
      }

      span {
        border-bottom: 2px solid #E9B51C;
      }
    }
    .second_top_field {
      margin-bottom: 40px !important;
    }
    .cus_code {
      margin-bottom: 8px;
    }
    .m-0 {
      font-weight: 400;
      font-size: 12px;
      color: #C1C1C1;
      opacity: 0.8;
    }


  }
}

@media screen and (max-width: 600px) {
  .my-profile-component {
    .title {
      display: none;
    }
    .top_container{
      margin-bottom: 0;
    }
    .w-60{
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .input-feild-section{
      margin-bottom: 36px;
    }
    .space-divider{
      height: 40px;
      display: block;
    }
  }
}
