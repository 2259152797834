@import "../../assets/common/Colors";
@import "../../assets/common/Typography";

@mixin rating_count{
  font-family: "GilroySemiBold",sans-serif;
  font-size: 24px;
  line-height: 115%;
  margin-bottom: 2px;
}

@mixin rating_text{
  font-family: "GilroySemiBold",sans-serif;
  font-size: 16px;
  line-height: 115%;
  margin-bottom: 2px;
}

.rating-circle-component{
  .rating-circle-list{
    gap: 24px;
    .circle-item{
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      width: 110px;
      height: 110px;
      border-radius: 100%;
      .rating-count{
        @include rating_count();
        text-align: center;
      }
      .rating-text{
        color: #8F8F8F;
        @include rating_text();
      }
      &:nth-child(1){
        background: #FFFCF4;
        .rating-count{
          color: $squash;
        }
      }
      &:nth-child(2){
        background:  rgba(202, 255, 231, 0.44);
        .rating-count{
          color: #348B61;
        }
      }
      &:nth-child(3){
        background:  rgba(249, 224, 255, 0.6);
        .rating-count{
          color: #E57B95;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .rating-circle-component{
    .rating-circle-list{
      gap: 14px;
      .circle-item{
        width: 100px;
        height: 100px;
        border-radius: 100%;
        .rating-count{
          @include rating_count();
          font-size: 20px;
        }
        .rating-text{
          color: #8F8F8F;
          @include rating_text();
          font-size: 14px;
        }
        &:nth-child(1){
          background: #FFFCF4;
          .rating-count{
            color: $squash;
          }
        }
        &:nth-child(2){
          background:  rgba(202, 255, 231, 0.44);
          .rating-count{
            color: #348B61;
          }
        }
        &:nth-child(3){
          background:  rgba(249, 224, 255, 0.6);
          .rating-count{
            color: #E57B95;
          }
        }
      }
    }
  }
}
