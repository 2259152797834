
.dashboard-component{
  margin-top: 90px;
  min-height: 95vh;
  .new-user-card{
    padding: 80px;
    display: flex;

    gap: 80px;
    .content-section{
      .title{
        font-family: "GilroyBold",sans-serif;
        font-size: 32px;
        line-height: 39px;
        color: #191D27;
        margin-top: 0;
        margin-bottom: 40px;
      }
      .new-course-btn{
        background: #E9B51C;
        border-radius: 8px;
        padding: 10px;
        font-family: "GilroyBold",sans-serif;
        font-size: 24px;
        line-height: 175.3%;
        color: #191D27;
        border: none;
        &:focus{
          outline: none;
          border: none;
        }
      }
    }
  }
  .custom-select{
    background: #FFFFFF;
    border-radius: 8px;
    width: -moz-fit-content;
    width: -webkit-fill-available;
    max-width: 365px;
  }
  .existing-user-section{
    padding: 40px 24px;
    .top-section{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-section{
        display: flex;
        align-items: center;
        gap: 16px;
        .title{
          font-family: "GilroyBold",sans-serif;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #808080;
          margin: 0;
        }
        .current-month{
          font-family: "GilroyBold",sans-serif;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          color: #C49818;
          padding: 4px 20px;
          background: rgba(233, 181, 28, 0.29);
          border-radius: 4px;
        }

      }
      .right-section{
        width: 365px;
        div div .options{
          padding: 24px;
        }
      }
    }
    .dashboardfilter{
      .basic-button-component button{
        background-color: white!important;
        color: rgba(233, 181, 28, 1);
        width: 100%;
        text-align: left;
        position: relative;
        img{
          position: absolute;
          right: 20px;
          top: 15px;
        }
      }
    }

    .filterdropdown{
      font-family: 'Gilroy',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #4E4E4E;
      background: #FFFFFF;
      border-radius: 8px;
      gap: 12px;
      padding: 24px;
      position: absolute;
      flex-direction: column;
      z-index: 2;
      right: 0;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
      width: -moz-fit-content;
      width: -webkit-fill-available;
      max-width: 365px;
      right: 18px;
      div{
        cursor: pointer;
      }
    }
    .yelloline{
      border-bottom:0.5px solid rgba(233, 181, 28, 0.5);
      margin: 0;
    }




    .date-select{
      width:100%;
      background-color: white;
      padding-top: 24px;
      .inside-select{
        background: #FFF5D7;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        .row{
          padding-top: 32px;
          display: flex;
          align-items: center;
          .leftarr,.rightarr{
            background: #E9B51C;
            border-radius: 4px;
            width: 24px;
            height: 39px;
            padding-top: 9px;
            text-align: center;
          }
          .col-12{
            justify-content: space-around;
            margin-bottom: 32px;
          }
          .col-4{
            margin-bottom: 32px;
            padding: 8px 15px;
            text-align: center;
          }

          .endingdate{
            background: rgba(233, 181, 28, 0.24);
            border-radius: 0 38px 38px 0;
          }
          .startingdate{
            background: rgba(233, 181, 28, 0.24);
            border-radius: 38px 0 0 38px;
          }
          .middledate{
            background: rgba(233, 181, 28, 0.24);
          }
          .year{
            background: #FFFFFF;
            border: 0.921992px solid #E9B51C;
            padding-top: 10px;
            box-sizing: border-box;
            border-radius: 8px;
            height: 39px;
            width: 89px;
            text-align: center;
            .inputyear{
              border: none;
              background: transparent;
              padding-left: 20px;
              &:focus-visible{
                outline: none;
              }
            }
          }
        }
      }
    }
    .course-overview-section{
      background: #FFFFFF;
      border-radius: 8px;
      padding: 24px 12px;
      .title{
        font-family: "GilroyBold",sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-left: 13px;
        margin-top: 0;
        margin-bottom: 32px;
      }
      table{
        thead{
          background: #191D27;
          tr{
            th{
              font-family: "GilroyRegular",sans-serif;
              font-size: 16px;
              line-height: 19px;
              color: #FFFFFF;
              padding: 18px 24px;
            }
          }
        }
        tbody{
          tr{
            background-color: #F5F5F5;
            &:nth-of-type(odd){
              background-color: #FFFFFF;
            }
            td{
              font-family: "GilroyRegular",sans-serif;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
              padding: 18px 24px;
            }
          }
        }
      }
    }
    .charts-section{
      display: flex;
      gap: 24px;
      margin: 40px 0 32px 0;
      .chart-card{
        border-radius: 8px;
        padding: 24px 24px 0 24px;
        &.enrollment{
          width: 60%;
          background: #FFE6EF;
        }
        &.consultation{
          width: 40%;
          background: #CEFFD9;
        }
        .chart-header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 25px;
          position: relative;
          .chart-title{
            font-family: "GilroyBold",sans-serif;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            margin: 0;
          }
          .chart-filter{
            width: 40%;
          }
          .filterdropdown{
            max-width: 300px;
            right: 0;
            div{
              cursor: pointer;
              :hover{
                background-color: #e9b51c;
              }
            }
          }
        }
        .chart-count{
          font-family: "GilroyBold",sans-serif;
          font-size: 24px;
          line-height: 29px;
          &.enrollment{
            color: #E92929;
          }
          &.consultation{
            color: #46874C;
          }
        }
      }
    }
  }
}
