@import "../../../assets/common/Typography";

.profileTitles {
  display: flex;
  flex-direction: row;

  .activeclass {
    width: 80%;
    padding: 26px 30px 108px 18px;
  }

  .top {
    width: 100%;
  }

  .title_name {
    @include paragraph2_regular;
    font-size: 16px;
    font-weight: 400;
    color: #4E4E4E;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
    position: relative;
    padding-left: 30%;
    text-align: left;
    cursor: pointer;
  }

  .nav_bar {
    width: 20%;
    background-color: #FBFBFB;
    padding-top: 96px;
    padding-bottom: 84px;
    align-items: baseline;
  }

  .flex_name {
    flex-direction: column;
    gap: 40px;
  }

  .active1 {
    background: linear-gradient(270deg, #FFEDBA 0%, rgba(255, 251, 240, 0) 100%);
    //opacity: 0.2;
    font-weight: 800;

    .line {
      position: absolute;
      border-right: 4px solid #E9B51C;
      right: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
    }
  }

}
@media only screen and (min-width: 1500px) {
  .profileTitles{
    .nav_bar {
      width: 35%;
    }
  }
}
@media only screen and (min-width: 1800px) {
  .profileTitles{
    .nav_bar {
      width: 25%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .profileTitles{
    width: auto;
    height: auto;
    padding: 0;
    display: block;
    .nav_bar {
      width: 100%;
      padding: 0;
    }
    .activeclass {
      width: 100%;
      padding: 32px 24px 80px 24px;
    }

    .flex_name {
      flex-direction: row;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: 80px;
      background-color: #4E4E4E;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    .flex_name::-webkit-scrollbar {
      display: none;
    }
    .active2 {
      .line {
        position: absolute;
        border-top: 4px solid #E9B51C;
        bottom: 0;
        width: 100%;
      }
    }

    .title_name {
      position: relative;
      white-space: nowrap;
      font-size: 16px;
      color: white;
      padding: 19px 0;
      cursor: pointer;
    }
  }
}
