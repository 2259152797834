@import "../../../../assets/common/Typography";
.professor-course-detail-component{
  margin-top: 90px;
  min-height: 95vh;


  .codex-editor{
    //background: rgba(233, 181, 28, 0.05);
    //color: #4e4e4e;
    //border-radius: 8px;
    //border: 0.5px solid #E9B51C;
    .ce-block__content {
      max-width: 100%;
      //padding: 0 20px;
    }
  }
  .error-message{
    color: red;
    margin: 0;
  }
  .main-section{
    //background-color: #F2F5FA;
    padding: 26px 24px;

    .form-field{
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .textreadStae{
      position: absolute;
      width: 7px;
      height: 7px;
      right: 40px;
    }
    .Create{
      @include paragraph2_medium;
      line-height: 115%;
      white-space: nowrap;
      position: absolute;
      background-color: white;
      left: 20px;
      top:9px;
      padding-left: 10px;
      padding-right: 10px;
      z-index: 2;
    }
    .inside_customise{
      @include paragraph2_medium();
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 173.5%;
      color: white;
      background: #E9B51C;
      opacity: 0.8;
      border-radius: 8px;
      width: 128px;
      height: 40px;
      margin:  auto;
      margin-top: calc(32% - 40px);
    }
    .redStar{
      margin-top: -15px;
      margin-left: 5px;
    }
    .bg-input{
      background: rgba(233, 181, 28, 0.05);
      opacity: 0.72;
      border: 0.5px solid #E9B51C;
      border-radius: 8px;
      width: 100%;
      padding:24px 18px 20px 20px;
      margin-top: 16px;
      margin-bottom: 24px;
    }
    .disable-pointer{
      pointer-events: none;
    }
    select{
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background-image: url("../../../../assets/images/yellow_down_arrow.png");
      background-repeat: no-repeat;
      background-position: 90% center;
      background-color: white;
    }
    .insideCourseBackButton{
      width: 40px;
      height: 40px;
      //padding: 8px;
      border-radius: 100%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 24px;
        height: 24px;
      }
    }
    .heading-section{
      margin-left: 24px;
      .coursesname{
        @include heading3;
        font-size: 24px;
        line-height: 175.3%;
        //padding-left: 24px;
      }

    }
    .coursesLevel{
      padding: 3px 20px;
      background-color: rgba(192, 224, 187, 1);
      color: rgba(0, 0, 0, 1);
      border-radius: 4px;
      margin-left: 16px;
      margin-bottom: 10px;
      margin-top: 10px;
      font-family: Poppins,'sans-serif';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      height: 24px;
      text-align: center;
      color: #000000;
    }

    .title{
      //margin-left: 64px;
      .heading{
        @include heading2;
        font-size: 16px;
        line-height: 175.3%;
        color: #8B8B8B;
      }
      .titlebody{
        align-items: baseline;
      }
      .rating-content{
        gap: 10px;
      }
      .starvalue,.uservalue{
        font-family: Poppins,"sans-serif";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        //padding-left: 10px;
      }
    }
    .subtitles{
      display: flex;
      gap: 64px;
      margin-top: 54px;
      hr{
        border-top: 6px solid #E9B51C;
        position: absolute;
        width: 100%;
        top: 23px;
        border-radius: 16px;
      }
    }
    .enablestate{
      @include heading2;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      cursor: pointer;
      position: relative;
    }
    .disabledstate{
      @include heading6_medium;
      font-size: 24px;
      line-height: 29px;
      color: #5C5C5C;
      opacity: 0.5;
      cursor: pointer;
      position: relative;
    }
    .courses-edit-btn{
      text-align: -webkit-right;
      .basic-button-component{
        .btn{
          color:black;
          display: flex;
          align-items: flex-end;
        }
      }
    }
    .courses-body{
      background-color: #FFFFFF;
      padding: 24px;
    }
    .prices{
      gap: 40px;
      div{
        width: calc(100% / 3);
      }
    }
    .custom-file-upload {
      background: #FFFFFF no-repeat center center;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      display: inline-block;
      cursor: pointer;
      width: 254px;
      height: 120px;
      position: relative;
      .edit-bar{
        background: rgba(0, 0, 0, 0.21);
        border-radius: 8px;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 23px;
      }
    }
    input[type="file"] {
      display: none;
    }
    .name-field{
      @include paragraph2_medium;
      max-width: 208px;
      margin: auto;
      position: relative;
    }
    .savebtn{
      .basic-button-component{
        .btn{
          background-color: transparent;
          font-size: 20px;
          line-height: 170.1%;
          text-decoration-line: underline;
          color: #58A882;
          img{
            padding-bottom: 5px;
          }
        }
      }
    }

  }
}

