.video-player-component{
  .video-react {
    .video-react-big-play-button{
      display: none;
    }
    .video-react-poster{
      background-size: 64px;
    }
  }
}

@media (min-width: 601px) {
  .video-player-component {
    .video-react {
      width: 100% !important;
      height: 550px !important;
    }
  }
}
@media (max-width: 600px) {
  .video-player-component {
    .video-react {
      width: 100% !important;
      height: 200px !important;
    }
  }
}
