@import "../../assets/common/Colors";
@import "../../assets/common/Typography";

.course-preview-card-component{
  max-width: 480px;
  height: 100%;

  .first-section{
    //padding-top: 16px;
    padding-left: 35px;
    padding-right: 35px;
    position: relative;
    height: 100%;
    .best-sellers-tag{
      position: absolute;
      top: 30px;
      right: -3px;
      @media (max-width: 600px) {
      //left: 0;
        right: 0;
      }
    }
    .card-spacer{
      height: 16px;
    }
    .course-rating-circle{
      padding: 24px 0;
    }
    .course-name{
      color: $vampire_grey;
      font-size: 32px;
      font-family: "GilroyBold",sans-serif;
      line-height: 170.1%;
      margin-bottom: 0px;
    }
    .instructor-section-title{
      display: none;
      color: $vampire_grey;
      margin: 24px 27px;
      @include heading5();
    }

  }
  .course-instructor-section{
    padding: 0 34px 34px;
    margin-top: 23px;
  }
  .video-player-section{
    display: none;
  }
}

.price-card-component{
  gap: 24px;
  .original-price{
    color: #7AA085;
    font-size: 80px;
    line-height: 115%;
    font-family: "GilroyBold",sans-serif;
    font-weight: 800;
    margin-bottom: 0;
  }
  .override-price{
    color: #8F8F8F;
    @include heading5();
    margin-bottom: 2px;
  }
  .discount{
    color: #8F8F8F;
    @include heading6_semibold();
    margin-bottom: 0;
  }
}

.butnow-btn-component{
  .btn-section{
    gap: 8px;
    .buy-now-btn{
      background-color: $squash;
      color: $white;
      //padding: 15px 0;
      box-shadow: none;
      margin: 0;
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include paragraph1_semibold();
      text-transform: capitalize;
      &:hover,&:active,&:focus{
        box-shadow: none;
        color: $white;
      }
    }
    .heart-btn{
      background-color: $vampire_grey;
      padding: 0 18px;
      box-shadow: none;
      margin: 0;
      height: 48px;
      &:hover,&:active,&:focus{
        box-shadow: none;
        color: $white;
      }
    }
  }
}

.add-to-cart-btn-component{
  .add-to-cart-btn{
    background-color: $vampire_grey;
    //padding: 16px 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    color: #FFFFFF;
    height: 54px;
    @include paragraph1_semibold();
    text-transform: capitalize;
    .cart-icon{
      margin-right: 16px;
    }
  }
}

.best-sellers-tag-component{
  background: radial-gradient(45.24% 45.24% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), #8EBE78;
  border-radius: 4.81082px;
  color: $white;
  font-family: "PoppinsMedium",sans-serif;
  font-size: 12px;
  line-height: 18px;
  padding: 4px 14px;
  width: max-content;
}
.course-preview-container{
  .modal{
    .modal-dialog{
      .modal-content{
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  @media(max-width:768px){
    .modal{
      .modal-dialog{
        .modal-content{
          .modal-body{
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .course-preview-card-component{
    width: 400px;
    .course-name{
      font-size: 25px!important;
    }
    .original-price{
      font-size: 52px!important;
    }
    .rating-circle-component .rating-circle-list .circle-item{
      height: 96px!important;
    }
    .rating-count{
      font-size: 17px!important;
    }
    .rating-text{
      font-size: 13px!important;
    }
    .override-price{
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .course-preview-card-component{
    width: 100%;
    .video-player-section{
      display: block;
    }
    .first-section{
      padding-top: 12px;
      padding-left: 0;
      padding-right: 0;
      .add-to-cart-btn-component,.card-spacer{
        display: none;
      }
      .course-rating-circle{
        padding: 32px 24px;
        order: 2;
      }
      .course-name{
        margin-bottom: 24px;
        margin-left: 24px;
        width: 80%;
      }
      .instructor-section-title{
        display: block;
      }
      .price-and-buynow{
        padding: 0 24px;
        order: 3;
      }
      .preview-content-section{
        display: flex;
        flex-direction: column;
      }
    }
    .course-instructor-section{
      padding: 0;
      order: 1;
    }
  }
  .price-card-component{
    margin-bottom: 32px;
    .original-price{
      font-size: 56px;
    }
    .override-price{
      font-size: 16px;
    }
    .discount{
      font-size: 14px;
    }
  }
  .butnow-btn-component {
    .btn-section {
      .buy-now-btn{
        font-size: 24px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
  .course-preview-card-component{
    max-width: 500px;
    .first-section {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
