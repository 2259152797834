@import '../../../assets/common/Typography';
.professor-profile-component{
  background-color: #F2F5FA;
  padding: 36px 40px;
  margin-top: 90px;
.workhistory{
  border-radius: 8px;
}

  .input_parent{
    position: relative;
  }
  .error-message{
    color: red;
    position: absolute;
    width: -webkit-fill-available;
    left: 40%;
    bottom: -43px;
  }

  .form-control[readonly]{
    background-color: rgba(233, 181, 28, 0.05);
  }
  .header{
    //padding: 32px 0 0 0;
    h3{
      @include heading2;
      font-size: 36px;
      line-height: 115%;
      margin: 0;
    }
    @include heading2;
    font-size: 32px;
    color: #4E4E4E;
    display: flex;
    justify-content: space-between;
    .basic-button-component{
      button{
        background-color: #ECBE38;
        color: black;
      }
    }

  }
  .professorpic{
    width: 152px;
    height: 152px;
  }

  .change_button {
    margin-top: 6px;
    background: #ECBE38;
    border-radius: 13.3333px;
    width: 96px;
    height: 40px;
    text-align: center;
    padding-top: 8px;
  }
  .code {
    pointer-events: none;
  }
  .w-80{
    width: 80%;
  }
  .w-45{
    width: 45%;
  }
  .w-75{
    width: 75%;
  }
  .w-40{
  width: 40%;
}
  .w-22_7{
    width: 19%!important;
  }
  .w-55{
    width: 55%;
  }
  .w-82{
    width: 100%;
  }
  .mb-32px{
    margin-bottom: 32px;
  }
  .gap-10{
    gap: 10%;
  }
  hr{
    margin-top: 24px;
    margin-bottom: 0;
  }
  //.workbody{
  //  padding: 24px 40px 40px 40px;
  //}
  .customwidth{

    width: calc(64.5% + 234px);

  }
  .textmar{
    margin: 32px 40px 40px 40px;
  }
  .bgfirst{
    background-color: #FEFBF4;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    //display: none;
    //-webkit-appearance: none;
    width: 15px;
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    color: yellow;
    background-image: url("../../../assets/images/date_bg.png");
  }

  input,select{
    @include heading2;
    font-size: 16px;
    color: #4E4E4E;
    background: rgba(233, 181, 28, 0.05);
    border: 0.5px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px;
    min-width: 234px;
  }
  select{
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("../../../assets/images/yellow_down_arrow.png");
    background-repeat: no-repeat;
    background-position: 90% center;
    max-width: 234px;
  }
  .editable{
    background-color: rgba(255, 255, 255, 0.05)!important;
    border: 0.5px solid rgba(0, 0, 0, 0.25)!important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15)!important;
    input{
      background-color: transparent;
    }
  }
  .editable_date{
    input{
      background-color: rgba(255, 255, 255, 0.05)!important;
      border: 0.5px solid rgba(0, 0, 0, 0.25)!important;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15)!important;
      &:focus-visible{
        border: 0.5px solid  #E9B51C!important;
      }
    }
  }
  .Currentlyworkinghere{
    margin: 8px 0;
  }
  .Currentlyworkinghere_img{
    margin: 0 8px;
    width: 14px;
  }
  .rdt{
    height: 50px;
    input{
      padding: 15px;
      height: 100%;
    }
  }
  .editable_2{
    background-color: rgba(255, 255, 255, 0.05)!important;
    input{
      background-color: transparent!important;
      border: 0.5px solid rgba(0, 0, 0, 0.25)!important;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15)!important;
    }
  }
  .not-editable{
    pointer-events: none;
  }
  .not-editable_date{
    input{
      pointer-events: none;
    }
  }

  .field_Name{
    @include paragraph2_regular;
    line-height: 115%;
    color: #4e4e4e;
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
  }
  .img_full_name{
    margin-top: 76px;
  }
  .myself{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 32px 40px;
    margin-bottom: 40px;
    .myself_body{
      .button_edit_delete{
        .basic-button-component{
          button{
            background: #ECBE38;
            border-radius: 13.3333px;
            padding:8px 36px ;
          }
        }
      }
      .editbutton{
        .basic-button-component{
          button{
            background: rgba(0, 0, 0, 0.21);
            border-radius: 13.3333px;
            padding:8px 12px ;
          }
        }
      }
      .body_bottom{

      }
    }
  }
  .bodycount{
    margin-bottom: 24px;
    justify-content: space-between;
  }
  .myself_body{
    margin-top: 32px;
  }
  .count{
    @include heading2;
    font-size: 16px;
    background-color:#E9B51C;
    padding:4px 9px 6px 8px;
    width: 24px;
    height: 24px;
    color:white;
    border-radius: 50%;
  }

  .workhistory,.EducationalHistory,.aboutyourself{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
    //padding: 32px 40px;
    .header{
      padding: 32px 40px 0 40px;
    }
    .workbody{
      padding: 24px 40px;
      margin-top: -10px;
      margin-bottom: -30px;
      border-radius: 8px ;
    }
  }
  .EducationalHistory,.workhistory{
    .addnewbtn{
      background: #FEFBF4;
      border-radius: 8px;
      min-height: 120px;
      padding-top: 40px;
      text-align: center;
      .top_btn{
       .basic-button-component{
           .btn{
             color: #000000;
           }

       }
      }
    }
    .workbody{
      .bodycount{
        .addtodb{

          .basic-button-component button{
            height: 44px;          }
        }
        .delecttodb{
          .basic-button-component button{
            background: rgba(0, 0, 0, 0.21);
            border-radius: 4px;
          }

        }
      }
    }


  }
  .aboutyourself{
    .yourselftextatre{
      textarea{
        @include paragraph2_regular;
        line-height: 170%;
        color: #4e4e4e;
        background: rgba(233, 181, 28, 0.05);
        border: 0.5px solid #E9B51C;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 24px 24px 32px 38px;
        width: calc(70.5% + 234px);
        min-height: 380px;
      }
    }
    .editable,.not-editable{
      padding: 24px 40px;
    }
    .editable_date,.not-editable_date{
      input{
        padding: 24px 40px;
      }
    }
  }
  .codex-editor{
    background: rgba(233, 181, 28, 0.05);
    color: #4e4e4e;
    border-radius: 8px;
    border: 0.5px solid #E9B51C;
  }

}

@media screen and (max-width: 1400px) {
.professor-profile-component {
  .workhistory {
    .insidework {
      .w-22_7 {
        width: 15% !important;
      }

      .customwidth {
        width: calc(66.5% + 234px);
      }
    }

  }
}

}