@import '../../../assets/common/Typography';
.topheader{
  background: #FFFFFF;
  box-shadow: 0px -8px 23px rgba(0, 0, 0, 0.25);
  height: 80px;
  padding-left: 29px;
  padding-right: 24px;
  align-items: center;
  position: fixed;
  width: 75%;
  z-index: 10;
  .newnotification{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #E9B51C;
  }
  .name{
    @include paragraph2_regular;
    font-size: 24px;
    line-height: 28px;
    color: #787878;
    span{
      @include heading2;
      margin-left: 11px;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
    }
  }
  .notification{
    width: 40px;
    height: 40px;
    background-color: #F2F5FA;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
  }
  .closebtn{
    position: absolute;
    left: 29px;
    top: 29px;
    cursor: pointer;
  }
  .notificationtitle{
    @include heading6_semibold;
    font-size: 32px;
    line-height: 120%;
    color: #4E4E4E;
    margin-top: 8px;
  }
  .viewbtn{
    @include heading6_semibold;
    font-size: 14px;
    line-height: 120%;
    color: #E9B51C;
    cursor: pointer;padding: 8px;
  }
  .bodycontent{
    padding: 8px;
  }
  .maincontent{
    @include paragraph2_regular;
    font-size: 16px;
    line-height: 120%;
    color: rgba(78, 78, 78, 0.5);

    p{
      span{
        @include heading2;
        font-size: 14px;
        line-height: 120%;
        color: #4E4E4E;
        margin-top: 12px;
      }
    }
  }
  .modal-dialog{
    max-width: 580px;
    width: 580px;
  }
}