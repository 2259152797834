@import "../../assets/common/Colors";
@import "../../assets/common/Typography";

.about-course-component{
  .title{
    color: $vampire_grey;
    font-size: 32px;
    font-family: "GilroyBold",sans-serif;
    line-height: 115%;
    margin-bottom: 18px;
  }
  .description-wrapper{
    position: relative;

    .description{
      @include heading6_medium();
      color: $vampire_grey;
      overflow: hidden;
      transition: 0.5s;
      th, tr , td {
        background-color: transparent !important;
        border: 1px solid #000;
      }
    }
    // .read-more-content,.read-less-content{
    //   display: none;
    // }

    .read-more-content,.read-less-content{
      display: flex;
      justify-content: center;
      align-items: end;
      cursor: pointer;      
      background: transparent;
      position: absolute;
      bottom: -40px;
      padding: 15px 0;
      width: 100%;
      height: 100px;
      transition: 0.5s;
      span{
        @include paragraph3();
        color: #E9B51C;
        font-family: "GilroyMedium",sans-serif;
      }
      .arrow-down{
        margin-left: 8px;
      }
    }
    .read-less-content {
     display: none;
      .arrow-down{
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 600px) {
  .about-course-component{
    .title{
      font-size: 24px;
      margin-bottom: 40px;
    }
    .description-wrapper{
      .description{
        @include heading6_medium();
        color: $vampire_grey;
        // height: 100px;
       
      }
      .read-more-content,.read-less-content{
        background: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1));
      }
    

    }
  }
}

// @media screen and (min-width: 768px) and (max-width: 1024px){
//   .about-course-component {
//     .description-wrapper{
//       .read-more-content,.read-less-content{
//         display: none;
//       }
//     }
//   }
// }
