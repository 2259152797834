@import "../../../assets/common/Typography";
.ViewDetails{
  margin: 40px 80px 80px 40px ;
  .sub_heading{
    @include heading2;
    font-size: 26px;
    color:#4E4E4E;
  }
  .orders{
    @include heading2;
    font-size: 24px;
    color:#4E4E4E;
    margin-bottom: 24px;
  }
  .sub_heading{
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .orderid{
    @include heading2;
    font-size: 24px;
    font-weight: 600;
    color:#8F8F8F;
    padding-left: 16px;
  }
  .voice_text{
    @include heading2;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 27.6px;
    color:#067B27;
  }
  .higher{
    background-color:#E9B51C !important;
    height: 2px;
  }
  .reminder_texer {
    background-color: #E9B51C;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px -8px;
    cursor: pointer;
    border-radius: 25px;
    padding: 10px 28px;
  }
  .text_para{
    @include heading2;
    font-size: 16px;
    font-weight: 900;
    line-height: 28.8px;
    color:#4E4E4E;
  }
  .text_paras{
    @include heading2;
    font-size: 16px;
    font-weight: 600;
    color:#8F8F8F;
  }
  .card1{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 22px;
    margin-bottom: 24px;
  }
  .dk_button1{
    display: none;
  }
  .mb_orders{
    margin-bottom: 16px;
  }
  .card_head{
    margin-bottom: 4px;
  }
  .dk_button{
    .rounded-button-component{
      .btn{
        margin-bottom: 0;
      }
    }
  }
  .text_para{
    margin-bottom: 5px;
  }
  @media screen and (max-width: 770px) {
    .voice_text{
      line-height: 170.1%;
    }
    .dk_button{
      display: none;
    }
    .dk_button1{
      display: block;
      .btn{
        margin: 0;
      }
    }
    .sector_flex{
      flex-direction: column;
    }
    .first_container{
      flex-direction: column;
      margin: 16px 0;
    }
    .orderid{
      padding-left: 8px;
    }
    .mb_orders{
      display: flex;
      flex-direction: column;
    }
    .mb_orderid{
      padding-left: 0;
      font-size: 20px;
      font-weight: 600;
      margin-top: 16px;
    }
    .sub_heading{
      font-size: 24px;
      font-weight: 600;

    }
    .orders{
      font-size: 20px;
      margin-top: 32px;
    }
    .voice_text{
      font-size: 20px;
    }
    .card_head,.text_paras{
      margin: 0;
    }
    .text_para{
      margin-top: 32px;
    }
.text_para{
  margin-top: 8px;
}
.dk_button1{
  margin-top: 32px;
}
    .mb_title{
      display: flex;
    }
    .mb_title_button{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      margin-top: 10px;
      text-align: center;
    }
    .mb_title{
      @include heading2;
      font-size: 24px;
      line-height: 120%;
      color: #4E4E4E;
      align-items: center;
      margin-bottom: 40px;
    }
  }
}
