.footer-component{
  .footer_section{
    padding: 120px 0 99px;
    .footer_heading{
      font-family: "GilroySemiBold",sans-serif;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 28.8px;
      display: block;
      color: #4E4E4E;
      margin-bottom: 24px;
      white-space: nowrap;
    }
    .logo{
      display: flex;
      align-items: center;
    }
    .footer_subheading{
      font-family: "GilroySemiBold",sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.83px;
      font-style: normal;
      color: #4E4E4E;
    }
    .sub_logo{
      width: 20.1px;
      height: 20px;
      margin-right: 16px;
    }
    .logo_relative{
      font-size: 16px;
      font-weight: 400;
      line-height: 18.83px;
      font-style: normal;
      color: #4E4E4E;
      width:75px;
      height: 19px;
    }
  }
  .footer_section_mob {
    display: none;
  }
}




@media only screen and (max-width: 600px) {
  .footer-component{



    .footer_section_mob {
      background-color: #4E4E4E;
      display: block;
      .row{
        .col-lg-3{
          .foot_logo_pic{
            max-width: 248px;
          }
        }
      }


    }

    .footer_section{
      display: none;
    }
    .mobile_subheading{
      list-style: none !important;
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      line-height: 24px;
      color: #FFFFFF;
      text-decoration: none !important;
      margin-bottom: 28px;
      width: 100%;
      ul{
        padding-left: 24px;
        li {
          list-style: none !important;
          font-size: 20px;
          font-weight: 700;
          font-style: normal;
          line-height: 24px;
          color: #FFFFFF;
          text-decoration: none !important;
          &:not(last-child){
            margin-bottom: 22px;
          }
        }
      }
    }
  }
}

