@import "../../assets/common/Typography.scss";
@import "../../assets/common/Colors";
.CourseDetails {
  .top-head {
    background-image: url("../../assets/images/wave-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 24px;
    padding-bottom: 24px;
    .topice {
      @include heading2();
      font-size: 36px;
      color: #4e4e4e;
    }
    .level,
    .Expirimg {
      @include heading6_medium();
      color: #4e4e4e;
    }
    .Expirimg {
      @media only screen and (max-width: 600px) {
        font-size: 8px;
        opacity: 1;
      }
    }
    .Name {
      @include heading5();
      line-height: 120%;
      color: #4e4e4e;
    }
  }
  .Tab {
    background: #4e4e4e;
    .tab-flex-container{
      gap: 12rem;
    }
    .topic-1,.topic-2 {
      line-height: 120%;
      color: white;
      padding-top: 26px;
      padding-bottom: 26px;
      cursor: pointer;
      font-family: "GilroyRegular",sans-serif;
    }
    .topic-1.active,.topic-2.active{
      border-bottom: 8px solid #E9B51C;
    }
  }
  .tab-content-section{
    padding: 24px 0;
    .cardPro {
      .mainCard {
        border-radius: 12px;
        padding: 14px;
        .title {
          @include heading2();
          font-size: 32px;
          text-align: center;
        }
        .para {
          @include heading2();
          font-size: 16px;
          text-align: center;
          color: #8f8f8f;
        }
        &:nth-of-type(1){
          background-color: #FFFEF3;
          .title {
            color: rgba(233, 181, 28, 1);
          }
        }
        &:nth-of-type(2){
          background-color: rgba(241, 252, 246, 1);
          .title {
            color: rgba(111, 182, 148, 1);
          }
        }
        &:nth-of-type(3){
          background-color: rgba(255, 244, 244, 1);
          .title {
            color: rgba(163, 72, 72, 1);
          }
        }
      }
    }
    .MainTab {
      margin-top: 24px;
      .course-accordian-component{
        .accordion{
          .accordion__item{

            .accordion__heading{
              .accordion__button{
                padding:8px 24px;
                .lesson-heading-component{
                  .large-device-heading{
                    .accordian-arrow{
                      width: 24px;
                    }
                    .module-name{
                      font-size: 16px;
                    }

                  }
                  .small-device-heading {
                    .accordian-arrow {
                      width: 24px;
                    }
                  }
                }
              }
            }

            .accordion__panel{
              .lesson-item-component{
                .left-section{
                  img{
                    width: 26px;
                  }
                  .lesson-name{
                    font-size: 16px;
                  }

                }
                &:not(:last-child) {
                  padding-bottom: 8px;
                }
                &:not(:first-child) {
                  padding-top: 8px;
                }
              }
            }
          }
        }
      }
    }
    .Info {
      .mb-cardd{
        display: none;
        @media only screen and (max-width: 600px) {
          display: block;
          margin-left: -24px;
          margin-right: -24px;
        }
      }
      .title-main{
        @include heading5();
        line-height: 120%;
        color:rgba(78, 78, 78, 1);
        margin-top: 40px;
        margin-bottom: 23px;
      }
      .card1 {
        border: 1px solid #e9b51c;
        max-width: 520px;
      }
      .title {
        @include heading2();
        color: #4e4e4e;
        font-size: 36px;
        margin-bottom: 22px;
      }
      .myflex{
        gap: 24px;
      }
      .ratei{
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 600px) {
          justify-content: inherit;
        }
      }
      .coursecontent{
        display: flex;
        align-items: center;
        justify-content: center;


      }
      .newrow{
        margin-top: 16px;
        margin-bottom: 40px;
      }
      .bg-card {
        background-image: url("../../assets/Illustrations/Polygon-Dark.png");
        background-repeat: no-repeat;
        background-position-y: bottom;
      }
      .instructor-image-section {
        position: relative;
        top: 0;
        left: 0;
        .instructor-bg {
          position: relative;
          top: 0;
          left: 0;
        }
        .instructor-profile {
          position: absolute;
          left: 50%;
          //bottom: -1px;
          transform: translateX(-50%);
          width: 100%;
          top: -7px;
        }
      }
      .instructor-detail {
        .title {
          color: #c8c8c8;
          margin-bottom: 8px;
          @include heading5();
        }
        .instructor-name {
          color: $vampire_grey;
          font-family: "GilroyBold", sans-serif;
          margin-bottom: 4px;
          @include paragraph1();
        }
        .instructor-role {
          color: $vampire_grey;
          font-family: "GilroyBold", sans-serif;
          @include paragraph3();
          margin-bottom: 4px;
        }
        .instructor-institute,
        .instructor-address {
          color: $vampire_grey;
          font-family: "GilroyMedium", sans-serif;
          @include paragraph3();
          margin-bottom: 4px;
        }
        .instructor-address {
          margin-bottom: 8px;
        }
        .large-device-experiance{

          display: flex;

        }
      }
      .rating-circle-component  {
        .rating-circle-list{
          gap: 14px;
        }
        .circle-item{
          width: 10rem;
          height: 10rem;
          .rating-count{
            font-size: 32px;
          }
          .rating-text{
            font-size: 24px;
          }
        }
      }
    }
    .details {
      .about-course{
        display: flex;
        gap:50px;
        .title{
          color: $vampire_grey;
          font-size: 32px;
          font-family: "GilroyBold",sans-serif;
          line-height: 115%;
          margin-bottom: 18px;
          // width: 30%;
          white-space: nowrap;
        }
        .description-wrapper{
          position: relative;
      
          .description{
            @include heading6_medium();
            color: $vampire_grey;
            overflow: hidden;
            transition: 0.5s;
            th, tr , td {
              background-color: transparent !important;
              border: 1px solid #000;
            }
          }
          .read-more-content,.read-less-content{
            display: flex;
            justify-content: center;
            align-items: end;
            cursor: pointer;      
            background: transparent;
            position: absolute;
            bottom: -40px;
            padding: 15px 0;
            width: 100%;
            height: 100px;
            transition: 0.5s;
            span{
              @include paragraph3();
              color: #E9B51C;
              font-family: "GilroyMedium",sans-serif;
            }
            .arrow-down{
              margin-left: 8px;
            }
          }
          .read-less-content {
           display: none;
            .arrow-down{
              transform: rotate(180deg);
            }
          }
        }
      }
      hr {
        border: 1px solid #e9b51c !important;
        margin: 40px 0 !important;
      }
    }
    .review-section{
      .Reviews {
        .title {
          @include heading3();
          color: rgba(78, 78, 78, 1);
          line-height: 115%;
          margin-bottom: 40px;
          margin-top: 40px;
        }
      }
      .OurStudentsWords {
        margin-bottom: 40px;
        .title {
          @include heading3();
          color: rgba(78, 78, 78, 1);
          font-size: 24px;
        }
        .card {
          background: #ffffff;
          box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          //margin-right: 50px;
        }
        .card {
          position: relative;
          padding: 30px 17px 41px 40px;
        }
        .Group19880 {
          position: absolute;
          right: 40px;
          top: 34px;
        }
        .leftrightArrow {
          margin-top: 44px;
          margin-bottom: 44px;
          margin-right: 40px;
          justify-content: end;

        }
        .span-studentName {
          padding: 8px 16px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
        }
        .studentWords {
          padding-top: 16px;
          color: #9d9d9d;
        }
        .nextstudentbtn {
          width: 88px;
          height: 88px;
          padding: 33px;
          border: 1px dashed #e9b51c;
          border-radius: 50%;
          margin-right: 30px;
          @media only screen and (max-width: 1200px) {
            width: 48px;
            height: 48px;
            padding: 13px;
          }
        }
        .left-Arrow,
        .Rigth-Arrow {
          width: 22px;
        }
        .mycard {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          height: fit-content;
          padding: 40px!important;
          .title{


            @include heading6_medium();
            font-size: 20px;
            color: rgba(78, 78, 78, 1);
          }

          textarea {
            border: 1px solid #e9b51c;
            box-sizing: border-box;
            border-radius: 5px;
            margin-bottom: 24px;
            width: 100%;
          }
          hr {
            border: 1px solid #e9b51c !important;
            margin-top:28px!important;
            margin-bottom: 11px!important;
          }
          .fa-star,.fa-star-half-alt{
            margin-right: 20px;
          }
        }
      }
    }

  }
  .create-reviews{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 40px;
    .reviews-header{
      @include heading6_bold;
      font-size: 20px;
      line-height: 170.1%;
      color: #4E4E4E;
      margin-bottom: 20px;
    }
    .custom-hr-line{
      border: 1px solid #F9EABF;
      margin-bottom: 11px;
    }
    .sub-text{
      @include heading6_bold;
      font-size: 20px;
      line-height: 170.1%;
      color: #4E4E4E;
      margin-bottom: 16px;
    }
    div{
      div{
        span{
          margin-right: 20px;
        }
      }
    }
    .text-area-reviews{
      border: 1px solid #E9B51C;
      box-sizing: border-box;
      border-radius: 5px;
      max-width: 553px;
      width: 100%;
      height: 194px;
      padding: 16px;
      margin-bottom: 18px;
    }
  }

}

@media only screen and (max-width: 600px) {
  .CourseDetails{
    .top-head {
      padding-top: 15px;
      padding-bottom: 15px;
      .topice{
        font-size: 20px;
      }
      .level,.Expirimg {
        font-size: 12px;
        opacity: 0.8;
      }
      .Name {
        font-size: 14px;
        line-height: 115%;
      }
    }
    .Tab {
      .topic-1,.topic-2{
        padding-top: 20px;
        padding-bottom: 18px;
        font-size: 16px;
      }
      .tab-flex-container{
        gap: 64px;
      }
    }
    .tab-content-section {
      .cardPro {
        display: none;
      }
      .MainTab{
        .course-accordian-component{
          .accordion{
            .accordion__item{
              .accordion__panel{
                .lesson-item-component{
                  .paid-right-section{
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .Info {
        .card1{
          margin: 24px 0;
          display: none;
        }
        .rating-circle-component .circle-item {
          width: 100px;
          height: 100px;
          .rating-count {
            font-size: 16px;
          }
          .rating-text{
            font-size: 14px;
          }
        }
        .instructor-detail {
          .large-device-experiance{
            display: none;
          }
          .instructor-name,.instructor-role{
            font-size: 14px;
          }
          .instructor-institute,instructor-address{
            font-size: 12px;
          }
        }
      }
      .details {
        .about-course{
          flex-direction: column;
          gap:0;
          .title{
            font-size: 24px;
            margin-bottom: 40px;
            width: 100%;
          }
          .description-wrapper{
            .description{
              @include heading6_medium();
              color: $vampire_grey;
              // height: 100px;
             
            }
            .read-more-content,.read-less-content{
              background: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1));
            }
          
      
          }
        }
      }
      .review-section {
        .Reviews {
          .title {
              font-size: 24px;
          }
        }
        .OurStudentsWords {
          .title {
            font-size: 24px;
            margin-top: 40px;
          }
          .mycard{
            padding: 16px;
          }
          .leftrightArrow{
            margin-right: 10px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
  .CourseDetails {
    .Info {
      .card1{
        margin: 24px 0;

      }
    }
    .details{
      .about-course{

        .description-wrapper{

          .read-more-content,.read-less-content{
            display: none !important;
          }
        }
      }
    }
  }
}
