@import "../../assets/common/Typography";

.cart-component {

  .coupon-card{
    background: rgba(233, 181, 28, 0.05);
    border: 0.5px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "GilroyBold",sans-serif;
    font-size: 16px;
    line-height: 115%;
    color: #4E4E4E;
    margin: 6px;
    width: 97%;
    &:focus-visible{
      outline: none;
    }
    &.active{
      background-color: #FFFFFF;
    }
  }
  //header
  .top_header {
    font-size: 32px;
    font-family: "GilroyBold",sans-serif;
    line-height: 36.8px;
    color: white;
    background-color: #4E4E4E;
    padding: 21px 80px;
    .top_header_span {
      padding-left: 16px;
      font-size: 16px;
      line-height: 18px;
    }
  }
  .main-content {
    margin: 40px 0;
  }
  //CartCard
  .CartCard_first_row {
    position: relative;
  }
  .BestSeller {
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #8EBE78;
    padding: 5px 11px;
    border-radius: 5px;
    color: white;
    font-family: GilroyRegular,"sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .star {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.21);
    font-family: GilroyRegular,"sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 9.6px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
  }
  .red_delete_text{
    font-family: GilroyRegular,"sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175.3%;
    color: #FF9191;
  }
  .edit_icon_text,.tic_mark_text{
    font-family: GilroyRegular,"sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175.3%;
    text-decoration-line: underline;
    color: #389652;
  }
  .edit_icon,.red_delete_img,.tic_mark_img{
    width: 20px;
    height: 20px;
  }
.enter_couponcode{
  font-family: GilroyRegular,"sans-serif";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 115%;
  color: #A5CB93;
}
  .yellow_star {
    padding-left: 4px;
    padding-right: 8px;
  }

  .Card_Carttitle {
    @include heading2;
    font-size: 20px;
    color: #4E4E4E;

  }

  .voucher-code-delete-remove{
    display: flex;
    justify-content: space-between;
  }


  .username {
    @include paragraph2_medium;
    display: flex;
    align-items: center;
    line-height: 115%;
    font-weight: 400;
    color: #787A91;
    padding-top: 19px;
    padding-bottom: 17px;
  }

  .btn {
    width: -webkit-fill-available;;
  }

  .CartCard_row {
    min-height: 192px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .rate {
    @include paragraph2_medium;
    color: #4E4E4E;
  }

  .full_rate {
    color: #787A91;
    opacity: 0.8;
  }

  .btn-flat {
    @include paragraph3_regular;
    line-height: 175%;
    box-shadow: none;
    white-space: nowrap;
    padding-left: 0;
    color: #9F9F9F;
    width: auto;
  }

  .sen_order {
    border: 1px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .order {
    @include heading6_bold;
    font-size: 24px;
    line-height: 175%;
    color: #E9B51C;
  }

  .amount, .value_amount, .total_cart, .Coupon_Discount, .code {
    @include heading2;
    font-size: 32px;
    color: #4e4e4e;
  }

  .Coupon_Discount, .total_cart, .code {
    font-size: 16px;
  }

  .value_amount {
    font-weight: 800
  }

  .gst {
    @include paragraph4_regular;
    line-height: 115%;
    color: #E9B51C;
    margin-top: 8px;
  }

  .code {
    color: #A5CB93;
    cursor: pointer;
  }

  .button_flex {
    position: absolute;
    bottom: 0;
    gap: 40px;
  }

  //mobile cart
  .md_cart_card {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 24px;
    //margin-left: -15px;
    //margin-right: -15px;
  }

  .md_title {
    @include heading5;
    line-height: 120%;
    color: #4E4E4E;
    margin: 40px 0;
  }

  .mb_title {
    @include heading6_bold;
    line-height: 115%;
    padding: 10px 0;
  }

  .mb_username {
    @include paragraph3_regular;
    line-height: 115%;
    color: #787A91;
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .mb_rate {
    @include paragraph2_bold;
    color: #4E4E4E;
  }

  .mb_full_rate {
    color: #787A91;
    opacity: 0.8;
  }

  .mb_btn {
    @include paragraph4_regular;
    line-height: 175%;
    font-weight: 400;
    color: #9F9F9F;
    opacity: 0.8;
    padding-top: 16px;
  }

  .mb_BestSeller {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
  }

  .custome_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 100;

    .btn {
      margin: 0;
      border-radius: 0;
      padding: 16px 0;
    }

  }
  .cart-error-message{
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
    margin-bottom: 80px;
    .cart-error-header{
      font-family: GilroyRegular,"sans-serif";
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 115%;
      color: #4E4E4E;
    }
    .cart-error-text{
      margin: auto;
    }
    .cart-error-para{
      font-family: GilroyRegular,"sans-serif";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 115%;
      color: #787A91;
    }
    .btn{
      max-width: 254px;
    }
  }



  @media only screen and (max-width: 950px) {
    .layout-container {
      padding: 0 24px;
    }
  }

}
@media screen and (max-width: 600px) {
  .cart-component{
    .cart-error-message{
     flex-direction: column;
      text-align: center;
      align-items: center;
      .cart-error-img{
        width: 155px;
        margin-bottom: 32px;
      }
      .cart-error-header{
        font-size: 20px;
        line-height: 115%;
        margin-bottom: 12px;
      }
      .cart-error-text{
        //margin-left: auto;
        margin-bottom: 24px;
      }
      .cart-error-para{
        font-size: 14px;
        line-height: 115%;
        max-width: 256px;
      }
      .btn{
        max-width: 254px;
      }
    }

    .top_header {
      background-image: url("../../assets/images/singnup_created_bg.png");
      background-color: #FFFBEF;
      color: #4E4E4E;
      font-size: 24px;
      line-height: 27px;
      padding: 14px 24px;
    }
    .top_header_span {
      font-size: 14px;
      line-height: 16px;
      padding-right: 8px;
    }
    .main-content{
      margin: 24px 0;
      .grid-column{
        padding: 0;
      }
    }
  }

}
