@import "../../assets/common/Typography";
.blog1{
  width: 100%;

.title1{
  @include heading2;
  background: url("../../assets/images/blog_bg.png");
  padding: 71px 0;
  text-align: center;
  font-size: 48px;
  line-height: 58px;
  color: white;
}
  .main-center{
padding: 48px 0;
  }
  img{
    width: 100%;
  }
  .secondary-container{
    .gap_24{
      padding-top: 50px;
    }
  }
  .fist-container,.secondary-container{
    .gap_24{
      overflow: hidden;
    }
    height: 50%;
  }
  .gap_24{
    height: 100%;
    gap: 24px;
  }
  .content{
    max-width: 280px;
  }
  .blog_page_img_1,.blog_page_img_2{
  height: 100%;
  }
  .blog_page_img_3{
    height: 70%;
  }
  .date{
    @include heading6_medium;
    line-height: 19px;
    color: #4E4E4E80;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .question{
    @include heading2;
    font-size: 32px;
    color: #4E4E4E;
  }
  .answer{
    @include paragraph2_regular;
    line-height: 27px;
    color: #4E4E4E;
    //max-height: 100px;
  }
  .basic-button-component{
    display: none;
  }
  .Blog_content_content{




.blog{
  .md-main-title{
    margin: 0 -24px;
  }
  .main-title{
    display: none;
  }
  .blogCard{
    .blogCardBg1{
      width: 100%;
    }
    .date{
      @include heading6_bold;
      line-height: 19px;
      color: rgba(78, 78, 78, 0.5);
      margin-top: 16px;
      margin-bottom: 0;
    }
    .question{
      @include heading2;
      font-size:32px;
      color: #4E4E4E;
      margin: 8px 0;
    }
    .answer{
      @include heading6_medium;

    }
  }

  .blog-row{
    margin: 40px 0;
    display: flex;
    overflow: auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

  }
  .blog-row::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
.md-main-title{
  display: none;
}
  @media only screen and (max-width: 765px) {
    .top-BlogCard {
      min-width: 90%;
      margin: 12px 0;
    }
  }

}
    .MbBlogeCard{
      display: flex;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      .rowMbBlogeCard{
        margin: 6px 0 ;
      }
      .top_blogCardBg1{
        padding: 0;
      }
      .blogCardBg1{
        height: 120px;
        width: 100px;
        border-radius: 5px;
      }
      .col-8 MbBlogeCard_2nd_row{
        padding: 0;
      }
      .title{
        @include paragraph4_regular;
        line-height: 160%;
        font-weight: 600;
      }
      .MbBlogeCard_2nd_row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 8px;
      }
      .datetime{
        justify-content: space-between;
      }
      .date,.time{
        @include paragraph4_regular;
        line-height: 14px;
        font-weight: 400;
        color:rgba(78, 78, 78, 0.5);
      }
    }
  }
  @media only screen and (max-width: 765px) {
    .blog{
      margin: 0 -24px;
    }
    .title1{
      padding: 16px 0;
      font-size: 16px;
      line-height: 19px;
    }
    .blog-row{
      margin: 24px 0!important;
    }
  }
}
