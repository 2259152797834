.order-confirmation{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .order-confirmation__actions{

  }
}
