@mixin Gilroy-16p-500w-175_3 {
  font-family: "GilroyMedium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 175.3%;
  color: #4e4e4e;
}

@mixin steps-container{
  width: 80%;
  background-color: white;
  border-radius: 16px;
  min-height: 80%;
  display: flex;
  margin: auto;
  position: relative;
}

@mixin close-button-position{
  position: absolute;
  top: -60px;
  right: -84px;
  width: 56px;
}

@mixin mobile-close-button-position{
  width: 35px;
  height: 35px;
  top: -20px;
  right: -20px;
  z-index: 2;
  //background-color: #ffffff;
  border-radius: 100%;
}

@mixin reffral-tag{
  @include Gilroy-16p-500w-175_3;
  line-height: 18px;
  background-color: rgba(142, 190, 120, 1);
  padding: 4px 12px 3.25px 13px;
  color: rgba(255, 255, 255, 1);
  float: right;
  border-radius: 4px 0 0 4px;
  margin-top: -24px;
  position: absolute;
  right: 0;
  top: 48px;
}

.Singup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  .yellow_crt_btn,
  .Detail,
  .profile {
    width: 40px !important;
    height: 40px !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(../../assets/icons/date_icon.svg) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
  }


  .error-message{
    color: red;
    margin: 8px 0 5px;
  }
  button {
    outline: none;
    border: none;
  }
  button:focus {
    outline: none;
  }
  .step1,.Step2,.Step3{
    @include steps-container();
    .close_bt{
      @include close-button-position();
      cursor: pointer;
    }
    .inputfield {
      padding: 24px 5%;
      position: relative;
      .title {
        font-family: "GilroyMedium",sans-serif;
        font-size: 16px;
        color: #4e4e4e;
      }
      .span-ref {
        @include reffral-tag();
      }
      .conf_phone {
        position: relative;
        .w-62{
          min-height: 62%!important;
        }
        .conf-btn {
          @include Gilroy-16p-500w-175_3;
          font-weight: bold;
          line-height: 18px;
          background-color: rgba(233, 181, 28, 1);
          width: 15%;
          position: absolute;
          right: 0;
          top: 0;
          min-height: 100%;
          border-radius: 0px 8px 8px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
        }
        .yellow_crt_btn1 {
          position: absolute;
          right: 10px;
          top: 5px;
          width: 43px;
          height: 43px;
        }
      }
       form{
        padding: 42px 0 38px 0;
        .form-feild-item{
          margin-bottom: 24px;
        }
        .form-label{
          margin-bottom: 16px;
          @include Gilroy-16p-500w-175_3;
          line-height: 115%;
        }
      }
      .to_login {
        @include Gilroy-16p-500w-175_3;
        line-height: 115%;
        margin-bottom: 8px !important;
        cursor: pointer;
      }
      input,
      input:focus-visible,
      input[type="checkbox"],
      input[type="checkbox" i],
      .selector,
      .select1{
        background: #ffffff;
        border: 0.5px solid #e9b51c;
        border-radius: 8px;
        outline-color: #e9b51c;
        width: 100%;
        padding: 13px 14px 14px 14px;
      }
      .no-flex{
        gap: 32px;
        .flex1,.flex2 {
          width: 100%;
        }
      }
      .login-sapn {
        padding-left: 12px;
        color: rgba(233, 181, 28, 1);
      }
      .select1 {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background-image: url("../../assets/images/yellow_down_arrow.png");
        background-repeat: no-repeat;
        background-position: 90% center;
      }
    }
    .next {
      background-color: rgba(233, 181, 28, 1);
      border-radius: 0px 16px 16px 0px;
      display: flex;
      cursor: pointer;

      .rightArrow {
        margin: auto;
      }
    }
    .redStar {
      margin-top: -10px;
      padding: 2px;
    }
  }
  .Step2,.Step3{
    .inputfield {
      .span-ref {
        display: none;
      }
    }
    .no-flex {
      .selector {
        color: rgba(78, 78, 78, 1);
        height: 48px;
        position: relative;
      }
      .yellow_down_arrow {
        position: absolute;
        right: 16px;
        top: 21px;
      }
    }
    .impo {
      @include Gilroy-16p-500w-175_3;
      font-size: 12px;
      font-weight: 400;
    }
    .help {
      @include Gilroy-16p-500w-175_3;
      font-size: 14px;
      font-weight: 400;
      float: right;
      color: #e9b51c;
    }
    .ProceedBtn {
      display: none;
    }
  }
  .Final{
    .close_bt {
      @include close-button-position();
      width: 56px;
      height: 56px;
    }
  }
  .form-stepper-component{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .flow {
      background-color: rgba(124, 124, 124, 1);
      border-radius: 16px;
      min-height: 100%;
      padding: 24px 0;
      .process {
        font-family: "GilroyRegular",sans-serif;
        font-weight: 400;
        color: white;
        margin-top: 16px;
        margin-bottom: 0;
      }
      .inner {
        color: white;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      img {
        align-self: center;
      }

      hr {
        border: none;
        width: 1px;
        height: 88px;
        background-color: white;

      }
      .mb-on {
        cursor: pointer;
      }
    }
  }
  .step1 {
    .mb_ver_btn {
      background-color: rgba(233, 181, 28, 1);
      width: 100%;
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 5px;
      text-align: center;
      color: #ffffff;
    }
    .SenReCode {
      color: rgba(233, 181, 28, 1);
      display: none;
      margin:16px 0;

      //justify-content: end;
    }
    .mdconhelpmain {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 4px 28px 28px 28px;
    }
    .mdconhelpright {
      @include Gilroy-16p-500w-175_3;
      color: rgba(233, 181, 28, 1);
      font-weight: normal;
      font-size: 12px;
    }
    .mdconhelpleft {
      @include Gilroy-16p-500w-175_3;
      font-weight: normal;
      font-size: 12px;
      color: #4E4E4E;
      opacity: 0.7;
    }

  }
  .Final {
    padding: 76px 20px;
    div {
      @include Gilroy-16p-500w-175_3;
      font-weight: bold;
    }
    font-size: 24px;
    background-color: #fff9e9;
    background-image: url("../../assets/images/singnup_created_bg.png");
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
    position: relative;
    text-align: center;
    img {
      width: 267px;
      height: 206px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .welcome {
      @include Gilroy-16p-500w-175_3;
      font-weight: bold;
      font-size: 24px;
      color: #4E4E4E;
      //margin-bottom: 121px;
    }
    .finalCreate {
      @include Gilroy-16p-500w-175_3;
      font-weight: bold;
      font-size: 24px;
      color: #4E4E4E;
      //margin-top: 60px;

    }
  }
}

@media screen and (max-width: 600px) {
  .Singup {
    .step1,.Step2,.Step3,.Final{
      .SenReCode {
        display: block;
      }
      .close_bt{
        @include mobile-close-button-position();
      }
      .yellow_crt_btn1 {
        width: 20px!important;
        height: 36px!important;
      }
      .inputfield {
        flex: none !important;
        max-width: 100%;
        form{
          padding: 24px 0;
        }
        .title {
          display: none;
        }
        .span-ref {
          font-size: 14px;
          font-weight: 600;
          width: 100%;
          top: 24px;
          border-radius: 0;
          height: 32px;
          padding-top: 7px;
          text-align: center;
        }
        input,
        input:focus-visible,
        input[type="checkbox"],
        input[type="checkbox" i],
        .selector,
        .select1 {
          width: 100%;
          font-family: "Gilroy", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 170.1%;
          color: #4e4e4e;
          opacity: 0.5;
        }
        .to_login {
          padding-top: 36px;
          .login-sapn{
            //padding: 0;
          }
        }
      }
      .next {
        display: none;
      }
    }
    .Step2,.Step3{
      .impo {
        padding-top: 27px;
        padding-bottom: 28px;
      }
      .no-flex {
        display: block !important;
        width: 100%;
      }
      .next {
        display: none;
      }
      .ProceedBtn {
        display: block;
        background-color: #e9b51c;
        color: #ffffff;
        margin-top: 24px;
        padding: 11px;
        text-align: center;
        border-radius: 5px;
        border: 0.255469px solid #e9b51c;
      }
    }
    .form-stepper-component{
      .flow {
        display: flex;
        border-radius: 16px 16px 0 0;
        padding: 0;
        .process {
          font-size: 12px;
        }
        .inner {
          flex-direction: inherit;
          justify-content: space-evenly;
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .yellow_crt_btn,
        .Detail,
        .profile {
          width: 32px!important;
          height: 32px!important;
        }


        .mb-on {
          display: flex;
          flex-direction: column;
          position: relative;
        }


        hr {
          display: none;
        }
      }
    }
    .step1 {
      .no-flex {
        display: block !important;
        width: 100%;
      }
    }
    .Final {
      .close_bt{
        top: -50px;
        right: -30px;
      }
      .welcome {
        //margin-bottom: 76px;
        font-size: 20px;
        font-weight: 600;
      }
      .finalCreate {
        //margin-top: 76px;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
