@import "../../assets/common/Colors";
@import "../../assets/common/Typography";


.flat-button-component{
  button{
    background-color: $white;
    color: #58A882;
    padding: 10px 24px;
    box-shadow: none;
    text-transform: capitalize;
    @include paragraph2_regular();
    span{
      padding-bottom: 5px;
      border-bottom: 2px solid #58A882;
    }
    &:hover,&:active,&:focus{
      box-shadow: none;
      color: #58A882;
    }
    .edit-icon{
        margin-left: 8px;
    }
  }
}
