@import "../../../assets/common/Typography";

.professorcourses{
  background: #F2F5FA;
  padding: 45px 24px 56px 24px;
  margin-top: 90px;
  min-height: 95vh;
  .live-class{
    color: #58A882;
  }
  .parent-sort{
    div{
      .basic-button-component{
        .btn{
          height: 39.3px;
          background: none;
          border: 1px solid black;
          margin-right: 16px;
        }
      }
      .sortfield{
        right: 153px;
      }
    }
  }
  .error-message{
    color: red;
    margin: 0;
  }
  input,textarea,select,.input-feild{
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 100%;
    padding:24px 18px 20px 20px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  select{
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("../../../assets/images/yellow_down_arrow.png");
    background-repeat: no-repeat;
    background-position: 90% center;
    background-color: white;
  }
  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    outline: #ffa500 solid 1px;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    outline: #ffa500 solid 1px;
  }
  input[type="file"] {
    display: none;
  }

  .header{
    margin-bottom: 32px;
    .sorting-icon{
      padding: 8px 16px;
      border: 1px solid #4E4E4E;
      border-radius: 4px;
      width: 56px;
      height: 40px;
      margin: 0 16px 0 0;
      box-shadow: none;
    }

    .headertitle{
      @include heading2;
      font-size: 24px;
      line-height: 29px;
      color: #808080;
      margin: 0;
    }
    .basic-button-component{
      .btn{
        margin: 0;
        color:rgba(0, 0, 0, 1) ;
      }
    }
    .modal-dialog{
     margin: auto 0 auto auto;
      max-width: 612px;
      width: 612px;
    }
    .modalCloseBtn{
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .modal-body{
      padding: 40px;
    }
    .modal_title{
      @include heading2;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #4e4e4e;
      margin-bottom: 40px;
    }
    .form-field{
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
    }
    .Create{
      @include paragraph2_medium;
      line-height: 115%;
      white-space: nowrap;
      position: absolute;
      background-color: white;
      left: 20px;
      top:9px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .redStar{
      margin-top: -15px;
   }
    .textreadStae{
      position: absolute;
      width: 7px;
      height: 7px;
      right: 40px;
    }
    .top-form-field{
      gap: 24px;
    }
    .name-field{
      @include paragraph2_medium;
      max-width: 208px;
      margin: auto;
      position: relative;
    }
    .custom-file-upload {
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      //width: 254px;
      height: 120px;
      background: #FFFFFF no-repeat center center;
      position: relative;
      margin-bottom: 24px;
      .edit-bar{
        background: rgba(0, 0, 0, 0.21);
        border-radius: 8px;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 23px;
      }
    }
    .inside_customise{
      @include paragraph2_medium;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 173.5%;
      color: white;
      background: #E9B51C;
      opacity: 0.8;
      border-radius: 8px;
      width: 128px;
      height: 40px;
      //margin:  auto;
      //margin-top: calc(32% - 40px);
    }
  }
  .course-card-column {
    padding-left: 5px;
    padding-right: 5px;
  }

  .CourseCard {
    position: relative;
    background: #ffffff;
    box-shadow: 16.8987px 16.8987px 25.3481px rgba(0, 0, 0, 0.05);
    border-radius: 6.75949px;
    cursor: pointer;
    .bg {
      background-image: url("../../../assets/images/coursecard-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: 147.37px;
      border-radius: 6.75949px;
      .star1 {
        position: absolute;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 75px;
        text-align: center;
        display: flex;

        .Star {
          padding-right: 12px;
        }

        color: white;
      }

      .heart1 {
        position: absolute;
        right: 8px;
        top: 8px;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 63px;
        height: 40px;
        text-align: center;
        cursor: pointer;
        color: white;
        white-space: nowrap;
      }

    }
    .content-section{
      padding: 21px 0 22px;

      .courseName {
        font-family: "GilroyBold", sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
      }

      .smallUser {
        padding-right: 7px;
      }

      .parentUser {
        @include heading2;
        //padding-top: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 19px;
        color: #4E4E4E;
      }

      .RateTage {
        @include heading2;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
        padding-top: 7px;
      }

      .realRate {
        line-height: 120%;
        color: #787a91;
        opacity: 0.8;
        padding-left: 16px;
        padding-right: 11px;
      }

      .tag {
        background-color: rgba(6, 123, 39, 1);
        color: white;
        padding: 2px 9px;
        border-radius: 3.37975px;
      }
    }
    .cart-btn {
      position: absolute;
      right: 12px;
      bottom: 24px;
      border-radius: 6.75949px;
      width: 40px;
      height: 40px;
      text-align: center;
      cursor: pointer;
      background-color: #E9B51C;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }
    .status{
      @include paragraph2_medium;
      margin-top: 13px;
      font-size: 16px;
      line-height: 115%;
      color: rgba(233, 181, 28, 0.75);
    }
    .edit_button{
      outline: none;
      border: none;
      background: #4E4E4E;
      border-radius: 8px;
      width: 48px;
      height: 48px;
    }
  }
  .modal_final_buttom{
    .basic-button-component{
      margin-top: 44px;
      .btn{
        width: 100%;
        color: white;
      }
    }
  }
  .filterdropdown{

    font-family: Lato,'sans-serif';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #4E4E4E;
    border-radius: 0px 0px 24px 24px;
    gap: 30px;
    padding: 24px 24px 30px 24px;
    position: absolute;
    flex-direction: column;
    background: white;
    z-index: 2;
  }
}

.activeReviews{
  padding: 24px;
  .titleReviews{
    @include heading2;
    font-size: 36px;
    line-height: 115%;
    color: #4E4E4E;
    span{
      font-size: 16px;
      line-height: 175.3%;
      color: #4E4E4E;
      margin-left: 24px;
    }
  }
  .filterbtn{
    .basic-button-component{
      .btn{
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .header{
    position: relative;
  }
  .filterdropdown{

    font-family: Lato,'sans-serif';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #4E4E4E;
    border-radius: 0px 0px 24px 24px;
    gap: 30px;
    padding: 24px 24px 30px 24px;
    position: absolute;
    flex-direction: column;
    background: white;
    z-index: 2;
    right: 0;
    top: 50px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }
  .bodyreviews{
    background: #FFFFFF;
    box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 24px;
  }
  .verticalline{
    border-bottom: none;
    border-left: 2px solid rgba(78, 78, 78, 0.2);
    height: 40px;
    margin: 0 40px;

  }

  .reviewname{
    font-family: Poppins,"sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #413B3B;
    .qut{
      padding-right: 28px;
    }
  }
  .reviewspara{
    font-family: Poppins,"sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #9D9D9D;
  }
  .stardate{
    @include heading2;
    font-size: 16px;
    line-height: 175.3%;
    color: #4E4E4E;
  }
}
.activeModules{
.mainmoduleedit{
  .basic-button-component button{
    background-color:rgba(88, 168, 130, 1) ;
    color: white;
  }
}
}
