@import '../../assets/common/Typography';

.professors-detail-component {
  .carrer{
    .rigth-side{
      background-color: #FBF2DC;
      padding: 42px;
      .text{
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #4E4E4E;
        padding-top: 24px;
        padding-bottom: 40px;
      }
      .cross_bg {
        background: #8478CF;
        width: fit-content;
        transform: rotate(359deg);
        margin-left: -20px;
      }
      .consult {
        font-size: 38px;
        font-style: normal;
        line-height: 66px;
        color: #ffff;
        transform: rotate(1deg);
        white-space: nowrap;
        padding: 5px 11px;
        font-family: "GilroyBold",sans-serif;
      }
      .heading {
        font-family: 'Gilroy',sans-serif;
        //font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 66px;
        color: #4E4E4E;
        //font-family: "GilroyBold",sans-serif;
      }
    }
    .left-side{
      img{
        width: 100%;
        height: 100%;
      }

    }
  }





  .ProfessorsDetail_top {
    background-image: url("../../assets/images/singnup_created_bg.png");
    background-color: #4E4E4E;
    padding: 22px 80px;

    .title {
      @include heading1;
      font-size: 32px;
      color: #FFFFFF;
    }
  }
  .professors-detail-description {
    padding: 40px 0;
    .title{
      font-family: "GilroyBold",sans-serif;
      font-size: 32px;
      line-height: 39px;
      color: #4E4E4E;
      margin-bottom: 24px;
    }
    .description {
      @include heading6_medium;
      line-height: 26.56px;
      color: #4e4e4e;
      flex:1;
    }

    .ProfessorsDetail_image {
      float: right;
      margin-left: 30px;
      margin-bottom: 60px;
      width: 400px;
      height: 260px;
      object-fit: contain;
      //background-color: #C4C4C4;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      position: relative;

      img{
        max-width: 100%;
        max-height: 100%;
      }
      .professor-experience-section{
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: url("../../assets/Illustrations/Polygon-Dark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: absolute;
        right: -60px;
        bottom: -60px;
        .experience{
          font-family: "GilroyBold",sans-serif;
          font-size: 32px;
          line-height: 170.1%;
          color: #FFFFFF;
          margin-bottom: 0;
        }
        .content{
          font-family: "GilroySemoBold",sans-serif;
          font-size: 16px;
          line-height: 100%;
          color: #FFF7E0;
          margin-bottom: 0;
        }
      }
    }
  }

  .professors-course-list-component {
    .ProfessorsDetailtitle {
      display: flex;
      justify-content: space-between;
      align-items: start;
      .title{
        font-family: "GilroyBold",sans-serif;
        font-size: 32px;
        line-height: 39px;
        color: #4E4E4E;
        margin-bottom: 24px;
      }
    }
    .rounded-button-component {
      //margin-top: auto;
      //margin-bottom: auto;
      .btn {
        display: flex;
        align-items: center;
      }
      .text {
        white-space: nowrap;
        margin: auto;
      }
    }
    .search_Courses_row{
      .inside_cousrses_row{
        margin-bottom: 33px;
      }
    }
  }

}
@media only screen and (max-width: 600px) {
  .professors-detail-component{
    .ProfessorsDetail_top {
      padding: 14px 24px;
      .title{
        font-size: 24px;
      }
    }
    .professors-detail-description{
      padding: 0;
      .title {
        display: none;
      }
      .title2 {
        @include heading1;
        font-size: 24px;
        color: #4E4E4E;
        margin: 40px 0;
        padding: 0 24px;
      }
      .description{
        padding: 0 24px;
      }
    }
    .layout-container{
      padding: 0;
    }
    .professors-course-list-component{
      .ProfessorsDetailtitle {
        margin-top: 56px;
        margin-bottom: 38px;
        padding: 0 24px;
        .title{
          font-size: 24px;
          margin-bottom: 0;
        }
        button{
          width: max-content;
        }
      }
    }
  }
}
