@import '../../../assets/common/Typography';
.consultations{
  background-color: #F2F5FA;
  padding: 40px 24px 108px 24px;
  margin-top: 90px;
  min-height: 95vh;
  .top_index{
    display: flex;
    gap: 80px;
  }
  .parent-sort{
    div{
      .basic-button-component{
        .btn{
          .filter{
            padding-right: 8px;
          }
        }
      }
      .sortfield{
        right: 35px;
      }
    }
  }
  .indexactive{
    @include heading2;
    font-size: 28px;
    line-height: 29px;
    color: #000000;
    position: relative;
    cursor: pointer;
  }
  .indexinactive{
    @include paragraph2_regular;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #5C5C5C;
    opacity: 0.5;
    cursor: pointer;
  }
  .hilitetitle{
    border-top:6px solid #E9B51C;
    border-radius:6px;
    margin-bottom: -18px;
  }
  .bodymain{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    .header{
      display: flex;
      justify-content: space-between;
      padding: 6px 0 6px 9px;
      .bodymaintitle{
        @include heading2;
        font-size: 24px;
        line-height: 20px;
      }
      div{
        .basic-button-component{
          .btn{
            color: black;
          }
        }
      }
    }
    .table-striped tbody tr:nth-of-type(even){
      background: #F5F5F5;
    }
    .table-striped tbody tr:nth-of-type(odd){
      background: white;
    }
    div{
      .table{

        thead{
          @include paragraph2_regular;
          background-color: #191D27;
          color: white;
          line-height: 19px;
          padding-left: 24px;
          tr{
            th{
              padding-left: 24px;
              font-size: 16px;
            }
          }
        }
        tbody{
          tr{
            td{
              @include paragraph2_regular;
              font-size: 16px;
              color: black;
              line-height: 19px;
              padding-left: 24px;
            }
          }
        }
      }
      .topdropdown{
        //position: relative;
        //img{
        //  cursor: pointer;
        //}
        .dropdown{
          //button{
          //  background-color: transparent!important;
          //  box-shadow: none!important;
          //  padding: 0;
          //}
        }
      }
      .dropdownbtn{
        position: absolute;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 24px 24px;
        padding:24px 32px;
        gap: 30px;
        right: 30px;
        z-index: 2;
        div{
          cursor: pointer;
        }
      }
    }
  }
  .statusDecline{
    color: rgba(235, 26, 67, 0.5);;
  }
  .statusCompleted{
    color: #58A882;
  }
  .onlyedit{
    margin-top: -4px;
  }
  .limeimg{
    padding-top: 16px;
    padding-bottom: 39px;
  }
  .modal .modal-full-height{
    width: 580px!important;
  }
  .closebtn{
    padding-top: 26px;
    padding-left: 26px;
  }
  .modal .modal-dialog{
    max-width: 580px!important;

  }
  .modal .modal-dialog.modal-right{
    right: 0!important;
  }
  .modaldetails{
    margin-left: 64px;
  }
  .modal .modal-full-height {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 400px;
    height: auto;
    min-height: 100%;
    margin: 0;
  }
  .rcbtn{
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
  .rbtn{
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  .modaledit{
    margin-left: 19px;
  }
.sortfield{
  flex-direction: column;
  gap: 30px;
  padding: 24px 24px 30px 24px;
  position: absolute;
  background: #FFFFFF;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
}
  .ModaluserName{
    @include heading5;
    line-height: 29px;
    color: #4e4e4e;
  }
  .modalusercontact{
    @include paragraph2_regular;
    line-height: 19px;
  }
  .modalinstructorname{
  @include paragraph2_medium;
    font-size: 20px;
    line-height: 24px;
    color: #4E4E4E;
  }
  .rcname{
    @include paragraph2_medium;
    font-size: 14px;
    line-height: 17px;
    color: rgba(78, 78, 78, 0.4);
  }
  .rcfullcomponent{
    gap: 16px;
  }
  .wordEducationHistory{
    background: #FEFBF4;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
  .wordEducationHistory{
    padding-left: 64px;
    padding-top: 16px;
  }
  .modalmessage{
    padding-left: 64px;
    padding-top: 40px;
    padding-right: 40px;
  }
  .detsilscontent{
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .modal-body{
    padding: 0;
  }
  .modal_insidetitle{
    @include paragraph2_medium;
    font-size: 20px;
    line-height: 24px;
    color: #4E4E4E;
    opacity: 0.5;
  }
  .company{
    @include paragraph2_medium;
    font-size: 16px;
    line-height: 19px;
  }
  .modalcrouse{
    @include paragraph2_regular;
    color: #6F6F6F;
}
  .modalsubcrouse{
    @include paragraph2_regular;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 40.5px;
  }
  .modalpara{
    @include paragraph2_regular;
    font-size: 16px;
    line-height: 170%;
    color: rgba(78, 78, 78, 0.8);
  }




}
