@import "../../../assets/common/Typography";
@import "../../../assets/common/Colors";

.Reviews {
  //margin-top: 40px;
  //margin-bottom: 80px;
  .error-message{
    color: red;
    margin: 8px 0 5px;
  }
  .fa-star, .fa-star-half-alt {
    margin-right: 20px;
  }

  .cusdataerror {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular", sans-serif;
    color: $vampire_grey;
  }
  .editable{
    margin: 16px 0;
  }

  .editable textarea {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
    .shap {
      display: none;
    }
  }

  .title {
    @include heading2;
    font-size: 32px;
    margin-bottom: 32px;
    color: #4e4e4e;
  }

  .card1 {
    padding: 24px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;

    .title-section{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .title1 {
      @include heading2;
      font-size: 24px;
      margin-bottom: 0;
      color: #4E4E4E;
    }

    textarea {
      @include paragraph2_medium;
      width: 100%;
      //min-height: 136px;
      padding: 24px;
      color: #9D9D9D;
      line-height: 24px;
      font-weight: 400;
      background-color: transparent;
      outline: none;
    }

    .editicon, .card1 {
      cursor: pointer;
    }

    .name, .editicon {
      @include heading2;
      font-size: 16px;
      font-weight: 600;
      color: #8F8F8F;
    }

    .name {
      margin-bottom: 19px;
    }

    .inner_card {
      background: #FFFDF7;
      box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      margin: 16px 0;
      position: relative;
      min-height: 136px;
    }

    .para {
      @include paragraph2_medium;
      max-width: 90%;
      padding: 24px;
      color: #9D9D9D;
      line-height: 24px;
      font-weight: 400;
    }

    .shap {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .star4 {
      margin-top: 19px;
      margin-bottom: 3px;
    }

    .editicon {
      //float: right;
      //padding-bottom: 20px;
      //padding-left: 20px;
      color: #58A882;
      font-weight: 400;

    }

    .mb_col {
      float: none;
      text-align: end;
      margin-top: 20px;
    }


  }

  @media only screen and (max-width: 770px) {
    margin-top: -32px;
    .para {
      padding: 24px 16px !important;
      max-width: 100% !important;
    }
    .title {
      font-size: 24px;
      margin: 0 -24px 24px -24px;
      background-image: url("../../../assets/images/singnup_created_bg.png");
      background-color: #FFFBEF;
      padding: 31px 24px 38px 34px;
    }
    .title1 {
      margin-bottom: 20px !important;
    }
    .star4 {
      margin-top: 27px !important;
      margin-bottom: 11px !important;
    }

  }
}
