@import "../../../assets/common/Typography";

.ChangePassword{
  .error-message{
    color: red;
    margin: 8px 0 5px;
  }
  input,textarea,select{
    border: 0.5px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 8px;
    width: 50%;
    padding: 21px;
    margin-top: 16px;
    margin-bottom: 50px;
    background: white;
    &:focus-visible{
      outline-color: #e9b51c;
    }
  }
  .container_start{
    //margin-top: 65px;
  }
  .code_1{
    margin-bottom: 0;
  }
  .flex1{
    position: relative;
    width: 100%;
  }
  .Create{
    @include paragraph2_medium;
    line-height: 115%;
    white-space: nowrap;
    position: absolute;
    background-color: white;
    left: 20px;
    top:9px;
  }

  .buttons{
    width: 50%;
    display: flex;
    justify-content: space-between;
  }
  .test{
    .basic-button-component button{
      background-color:#CCCCCC;
    }
  }

  .flat-button-component{
    width: 50%;
    display: flex;
    justify-content: end;
    margin-top: 16px;
    margin-bottom: 34px;
    .btn{
      margin-right: 0;
      padding-right: 0;
    }

  }
  .Create{
    @include heading2;
    font-size: 16px;
    line-height: 115%;
      color: #4E4E4E;
      opacity: 0.8;
    background-color: white;
    padding: 0 5px;
  }
  .password{
    margin-bottom: 54px;
  }
  .first_password{
    margin-bottom: 34px;
  }

  @media only screen and (max-width: 770px) {
    margin-bottom: 80px;
    input,.flat-button-component,.buttons{
      width: 100%;
      &:focus-visible{
        outline-color:#E9B51C ;
      }
    }
    .container_start{
      //margin-top: 66px;
    }
    .flat-button-component{
      margin-top: 0;
    }
    .password{
      margin-bottom: 48px;
    }
  }
}
