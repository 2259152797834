
.professor-filter-button-class{
  text-align: end;
  .sortfield{
    flex-direction: column;
    gap: 30px;
    padding: 24px 24px 30px 24px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    z-index: 2;
    right: 0;
    div{
      cursor: pointer;
    }
  }
  div{
    .basic-button-component{
      .btn{
        color: black;
      }
    }
  }
}