@import "../../assets/common/Typography.scss";
@import "../../assets/common/Colors";

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}




.Homepage {
  .rightArrow{
    padding-left: 8px;
    margin-top: -3px;
  }

  .empty-message{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular",sans-serif;
    color: $vampire_grey;
  }
  a{
    color: #000000;
  }
  .startslearning{
    padding: 31px 80px 40px 80px;
    background: url("../../assets/images/bg_lines.png") no-repeat;
    background-size: cover;

    .course-list {
      flex-wrap: nowrap!important;
      gap: 24px!important;
    }
    .slick-initialized .slick-slide{
      padding-right: 24px;
    }
    .ongoing-courses-slider-container{
      overflow: hidden;
      .swiper-container{
        .swiper-wrapper{
          .swiper-slide{
            width: 80%;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      padding: 24px 0 40px 0;
      .heading-section{
        padding: 0 24px;
      }
    }
      .main-title{
      @include heading2;
      font-size: 32px;
      line-height: 115%;
      color: #4E4E4E;
      @media only screen and (max-width: 600px) {
      font-size: 24px;
      }
      }
    .sub-title{
      @include paragraph2_regular;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #4E4E4E;
    }
    .courses{
      background: #FFFFFF;
      box-shadow: 1px 1px 4px rgba(233, 181, 28, 0.25);
      border-radius: 8px;
      margin-right: 18px;
      &:hover{
        cursor:pointer;
      }
      @media only screen and (max-width: 600px) {
        min-height: 120px;
        .inside-courses{
          min-height: 120px;
          .image_parent{
            min-height: 120px;
          }
        }
      }
    }
    .image_parent{
      position: relative;
    }
    .image{
      //width: 103px;
      height: 136px;
      border-radius: 8px 0px 0px 8px;
      width: inherit;
      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
      }
    }
    .video_play_btn{
      position: absolute;
      left: 38%;
      top: 38%;
      @media only screen and (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }

    .title{
      @include paragraph2_regular;
      font-weight: 800;
      font-size: 16px;
      line-height: 170.1%;
      color: #4E4E4E;
      padding-top: 14px;
      padding-left: 12px;
      padding-bottom: 8px;
      margin: 0;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 170.1%;
        padding-top: 7px;
        padding-left: 8px;
        padding-bottom: 6px;
      }
    }
    .lesson{
      @include paragraph2_regular;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #4E4E4E;
      padding-left: 12px;
      padding-bottom: 8px;
      margin: 0;
      @media only screen and (max-width: 600px) {
        font-size: 10px;
        line-height: 170.1%;
        padding-left: 8px;
        padding-bottom: 6px;
      }
    }
    .name{
      @include paragraph2_regular;
      font-weight: normal;
      font-size: 16px;
      line-height: 175.3%;
      color: #4E4E4E;
      padding-left: 12px;
      padding-bottom: 8px;
      margin: 0;
      @media only screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 175.3%;
        padding-left: 8px;
        padding-bottom: 6px;
      }
    }
    .progress{
      //background-color: #E4E4E4;
      background: #E4E4E4;
      color: #389652;
      width: 100%;
      height: 11px;
      position: absolute;
      bottom: 0;
    }

    progress::-webkit-progress-value {
      background: #389652;
    }
    @media only screen and (max-width: 600px) {
      background: url("../../assets/images/small_bg.png");
      padding-top: 14px;
      padding-bottom: 31px;
    }
    @media only screen and (max-width: 600px) {

    }
  }
  .ScaleYourSummit{
    padding: 40px 80px;
    @media only screen and (max-width: 600px) {
      padding: 24px 40px;
    }
    .title{
      @include heading2;
      font-size: 64px;
      line-height: 115%;
      color: #4E4E4E;

      @media only screen and (max-width: 600px) {
        text-align: center;
        font-size: 24px;
        line-height: 120%;
        margin-top: 32px;
      }
    }
    .para{
      @include paragraph2_regular;
      font-size: 16px;
      line-height: 120%;
      color: #4E4E4E;
      @media only screen and (max-width: 600px) {
        text-align: center;
        font-size: 12px;
        line-height: 170.1%;
      }
    }
  }
  .bigBanner {
    width: 100%;
  }
  .smallBanner {
    margin-bottom: 40px;
    width: 100%;
  }
  .bannercarousel {
    .testimoni--wrapper{
      outline: none;
    }
    .poster {
      width: 100%;
      max-height: 90vh;
    }
    .slick-dots li {
      width: 16px;
      height: 8px;
      background: #FCF4DF;
      border-radius: 8px;
    }
    .slick-dots {
      .slick-active {
        width: 80px;
        height: 8px;
        background: #E9B51C;
        border-radius: 8px;
      }
    }
    @media only screen and (max-width: 600px) {
      //margin: 0 -24px;
      .slick-dots li {
        width: 7.8px;
        height: 3.9px;
        background: #FCF4DF;
        border-radius: 8px;
      }
      .slick-dots {
        .slick-active {
          width: 39.9px;
          height: 3.9px;
          background: #E9B51C;
          border-radius: 8px;
        }
      }
    }
  }
  .qualified {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 80px;
    .title {
      font-family: 'Gilroybold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: #4E4E4E;
      text-align: center;
      margin-bottom: 21px;
      //padding-top: 40px;
    }
    .para {
      font-family: 'GilroyRegular', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #4E4E4E;
      max-width: 1000px;
    }
    @media only screen and (max-width: 600px) {
      .title {
        font-size: 24px;
        margin-bottom: 12px;
        //padding-top: 36px;
      }
      .para {
        font-size: 12px;
        line-height: 170%;
      }
    }
  }
  .coursetopic {
    margin-bottom: 38px;
    #category-slider{
      .slick-prev,.slick-next{
        width: 54px;
        height: 54px;
        background: #fff;
        border-radius: 100%;
      }
      .slick-prev::before,.slick-next::before {
        display: none;
      }
      .slick-prev{
        left: 0;
      }
      .slick-next {
        right: 0;
      }
      .slick-list {
        margin-left: 55px;
        margin-right: 55px;
      }
      .slick-track {
        display: flex;
      }
      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
      }
    }
    .course-slider{
      overflow: hidden;
      .slick-list {margin: 0 -12px;}
      .slick-slide>div {padding: 0 12px;}
    }
    .title {
      @include heading5;
      line-height: 29px;
      color: #4E4E4E;
      text-align: center;
      cursor: pointer;
      padding: 8px 16px;
      &.active {
        background: #F9EABF;
      }
    }
    .fullcourses {
      margin-top: 42px;
      margin-bottom: 38px;
      .coursetopicBtn {
        text-align: center;
      }



    }
    @media only screen and (max-width: 600px) {
      .mbcoursetopic {
        .accordion {
          border: 0.3px solid #E9B51C;
        }
        .accordion__item + .accordion__item {
          border-top: 0.3px solid #E9B51C;
        }
        .accordian__header {
          background-color: #FFFFFF;
          cursor: pointer;
          padding: 24px;
          width: 100%;
          text-align: left;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.expanded{
            background: #FBF2DC80;
          }
          .accordian__title{
            @include paragraph2_bold;
            line-height: 115%;
            color: #4E4E4E;
            margin-bottom: 0;
            @media only screen and (max-width: 600px) {
              font-size: 14px;
            }
          }
        }

        [hidden] {
          display: none;
        }
        .accordian__body {
          animation: fadein 0.35s ease-in;
          padding: 24px 0;
          &.expanded{
            background: #FBF2DC80;
          }
        }

      }
    }
  }
  .ourExperts {
    .section-title{
      .main-title {
        font-size: 48px;
        font-family: "GilroyBold",sans-serif;
        color: #4E4E4E;
        margin-bottom: 59px;
      }
      .view-all{
        display: none;
      }
      @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .view-all{
          display: block;
          .rounded-button-component button {
            font-size: 12px;
            line-height: 15px;
            margin: 0;
          }
        }
        .main-title{
          font-size: 24px;
          margin-bottom: 0;
        }
      }
    }
    #experts-slider{
      .slick-prev,.slick-next{
        width: 54px;
        height: 54px;
        background: #fff;
        border-radius: 100%;
      }
      .slick-prev::before,.slick-next::before {
        display: none;
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
      .slick-list {
        margin-left: 55px;
        margin-right: 55px;
        @media screen and (max-width: 600px){
          margin-left: auto;
          margin-right: auto;
        }
      }
      .slick-track {
        display: flex;
      }
      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
      }
    }
    @media screen and (max-width: 600px){
      .layout-container{
        padding: 0;
      }
      .section-title{
        padding: 0 24px;
      }
    }
  }
  .TrendingCourses {
    .ownflex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 79px;
      @media screen and (max-width: 600px){
        margin-bottom: 40px;
      }
      .title {
        @include heading3;
        font-family: "GilroySemiBold", sans-serif;
        color: #4E4E4E;
        @media only screen and (max-width: 600px) {
          font-size: 24px;
        }
      }
      .rounded-button-component button {
        @media only screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 15px;
          margin: 0;
        }
      }
    }
    .small-device-trending-courses{
      display: none;
      padding-bottom: 40px;
      @media screen and (max-width: 600px){
        display: block;
      }
    }
    .large-device-trending-courses{
      @media screen and (max-width: 600px){
        display: none;
      }
    }
    @media screen and (max-width: 600px){
      .layout-container{
        padding: 0;
      }
      .ownflex{
        padding: 0 24px;
      }
    }
  }
  .carrer {
    .card_material {
      background-color: #FBF2DC;
    }
    .particular_flex{
      position: relative;
      .card_value_size {
        width: 297px;
        height: 360px;
      }
      .card_value {
        width: 267.63px;
        height: 137px;
        background-image: url("../../assets/images/quote-symbol.png");
        background-repeat: no-repeat;
        background-position: top left;
      }
      .sector {
        text-align: center !important;
        background: #FFFFFF;
        box-shadow: 0px 14px 48px -18px rgba(0, 0, 0, 0.13);
        border-radius: 8px;
        z-index: 1;
      }
      .card_value_size,.card_value,.card_sector_values,.card_value_size_part{
        .card-body{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .imagesss {
        position: absolute;
        top: -25px;
        right: 25px;
        z-index: 0;
      }
      .red_bg {
        position: absolute;
        bottom: 0;
        right: -45px;
        z-index: 0;
      }
      .violet {
        position: absolute;
        bottom: 25%;
        left: -22%;
        z-index: 0;
      }
      .dot {
        position: absolute;
        bottom: 0;
        left: -22%;
        z-index: 0;
      }
      .card-title {
        font-size: 20px;
        line-height: 23.54px;
        color: #000000;
        margin: 12px 0;
        font-family: "GilroyRegular",sans-serif;
      }
      .card-text {
        font-family: "GilroyRegular",sans-serif;
        font-size: 14px;
        line-height: 16.48px;
        text-align: center;
      }
      .card_titles {
        font-size: 14px;
        font-style: normal;
        line-height: 16.48px;
        text-align: center;
        font-family: "GilroyRegular",sans-serif;
      }
      .card_sector_values {
        width: 156px;
        height: 146.8px;
      }
      .yellow_star {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
      }
      .yellow_boy {
        width: 36px;
        height: 36px;
      }
      .girl_img {
        width: 92px;
        height: 92px;
        display: flex !important;
      }
      .card_value_size_part {
        width: 213px;
        height: 350px;
      }

    }
    .force_flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .cross_bg {
      background: #8478CF;
      width: fit-content;
      transform: rotate(359deg);
    }
    .consult {
      font-size: 38px;
      font-style: normal;
      line-height: 66px;
      color: #ffff;
      transform: rotate(1deg);
      white-space: nowrap;
      padding: 5px 11px;
      font-family: "GilroyBold",sans-serif;
    }
    .heading {
      font-size: 38px;
      line-height: 66px;
      color: #4E4E4E;
      font-family: "GilroyBold",sans-serif;
    }
    .sub_text {
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      color: #4E4E4E;
      font-family: "GilroyRegular",sans-serif;
    }
    .basic-button-component button{
      margin: 0;
    }
    @media only screen and (max-width: 600px) {
      .card_material {
        background: white;
      }
      .person_img {
        width: 72px;
      }
      .card_value_size {
        width: 164px !important;
      }
      .card-title {
        font-size: 12px !important;
        line-height: 14px !important;
      }
      .card-text {
        font-size: 12px !important;
        line-height: 14px !important;
      }
      .card_value {
        width: 164px !important;
      }
      .card_sector_values ,.card_value_size_part {
        width: 119px !important;
      }
      .girl_img {
        width: 51px !important;
        height: 51px !important;
      }
      .violet {
        bottom: 35% !important;
        left: -10% !important;
      }
      .imagesss {
        right: 0 !important;
      }
      .red_bg {
        bottom: 10% !important;
        right: -20px !important;
      }
      .sub_text{
        font-size: 14px !important;
      }
      .heading, .consult {
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        line-height: 39px;
        padding: 0 24px;
      }
    }
    @media screen and (max-width: 600px){
      .main_row{
        transform: scale(0.85);
      }
    }
  }
  .Carousel_IndustrialSpeakers {
    overflow: hidden;
    padding: 27px 0 96px 0;
    @media only screen and (max-width: 600px) {
      //margin-left: -24px;
      //margin-right: -24px;
      padding: 0 0 50px 0;
      .layout-container{
        padding: 0;
      }
    }
    .swiper-pagination {
      display: none;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
    .main-title {
      font-family: "GilroyBold",sans-serif;
      font-size: 48px;
      color: #4E4E4E;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .IndustrialSpeakers {
      width: 100%;
      height: auto;
      color: white;
      padding: 50px 50px 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      @media only screen and (max-width: 600px) {
        padding: 0;
      }
    }
    .main-poster {
      position: relative;
      margin-left: 22px;
      margin-right: 22px;
      border-radius: 37px;
      @media only screen and (max-width: 600px) {
        margin: 0;
        border-radius: 0;
      }
    }
    .sub-main {
      position: absolute;
      bottom: 0;
      padding: 50px;
      @media only screen and (max-width: 900px) {
        padding: 10px;

      }
    }
    .title {
      @include heading2();
      font-size: 32px;
      @media only screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 115%;
      }


    }
    .para {
      @include paragraph2_regular();
      line-height: 27px;
      @media only screen and (max-width: 800px) {
        line-height: 17px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 140%;
      }

    }

    .swiper-button-next {
      background-image: url("../../assets/images/carousel_right.png");
      width: 58px;
      height: 58px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .swiper-container {
      position: relative;
      @media only screen and (max-width: 600px) {
        padding-bottom: 10px;
      }
    }

    .swiper-button-next::after {
      display: none;
    }

    .swiper-button-prev {
      background-image: url("../../assets/images/carousel_left.png");
      width: 58px;
      height: 58px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .swiper-button-prev::after {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 7.8px;
      height: 3.9px;
      background: #FCF4DF;
      border-radius: 8px;
    }

    .swiper-pagination-bullet-active {
      width: 39px;
      height: 3.9px;
      background: #E9B51C;
      border-radius: 8px;
    }

    .swiper-pagination {
      @media only screen and (max-width: 600px) {
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .StudentsWords {
    .title {
      @include heading3();
      font-family: "GilroyBold", sans-serif;
      color: #767676;
      margin-bottom: 41px;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
      }
    }
    .Details {
      min-height: 294px;
      @media only screen and (max-width: 600px) {
        display: contents;
      }
    }
    .col-wave {
      margin-top: -98px;
      @media only screen and (max-width: 1100px) {
        display: flex;
        align-self: center;
      }
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .StudentWordscard {
      background: #ffffff;
      box-shadow: 0px 8px 44px -10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 24px;
      width: 80%;
      margin-bottom: 62px;
      @media only screen and (max-width: 600px) {
        margin-bottom: 40px;
        width: 100%;
      }
    }
    .span-studentName {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #413b3b;
      padding-left: 28px;
      @media only screen and (max-width: 600px) {
        padding-left: 6px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .studentWords {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #9d9d9d;
      margin-top: 33px;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 160%;
      }
    }
    .nextstudentbtn {
      width: 88px;
      height: 88px;
      border: 1px dashed #e9b51c;
      border-radius: 50%;
      &:not(:last-child){
        margin-right: 30px;
      }
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 600px) {
        width: 48px;
        height: 48px;
        padding: 14px;
        margin-right: 24px;
      }
    }
    .LeftArrow,
    .yellowrightArrow {
      width: 22px;
    }
    .top-wave {
      position: relative;
    }
    .wave-outer1 {
      position: absolute;
      top: 80%;
      left: 60%;
      @media only screen and (max-width: 1400px) {
        top: 90%;
        left: 70%;
      }
      @media only screen and (min-width: 1700px) {
        top: 400px;
        left: 500px;
      }
    }
    .wave-inner1 {
      position: relative;
    }
    .wavebackgroung1 {
      position: absolute;
      right: 50px;
      bottom: 50px;
      @media only screen and (max-width: 1100px) {
        width: 80px;
      }
    }
    .user1 {
      position: absolute;
      right: 60px;
      bottom: 150px;
      width: 137px;
      border-radius: 50%;
      @media only screen and (max-width: 1100px) {
        right: 51px;
        bottom: 95px;
        width: 78px;
      }
    }
    .wave-outer2 {
      position: absolute;
      top: 50%;
      left: 75%;
      @media only screen and (min-width: 1700px) {
        top: 200px;
        left: 300px;
      }
    }
    .wave-inner2 {
      position: relative;
    }
    .wavebackgroung2 {
      position: absolute;
      right: 50px;
      bottom: 50px;
      @media only screen and (max-width: 1100px) {
        width: 80px;
      }
    }
    .user2 {
      position: absolute;
      right: 60px;
      bottom: 150px;
      width: 137px;
      border-radius: 50%;
      @media only screen and (max-width: 1100px) {
        right: 51px;
        bottom: 95px;
        width: 78px;
      }
    }
    .style1 {
      @media only screen and (max-width: 600px) {
        float: right;
        width: 40px;
        height: 26px;
      }
    }
    .my_user {
      display: none;
      @media only screen and (max-width: 600px) {
        float: left !important;
        display: block;
        width: 24px;
        //margin-top: 5px;
      }

    }
    .wave-outer3 {
      position: absolute;
      top: 40%;
      left: 40%;
      @media only screen and (max-width: 1400px) {
        top: 50%;
        left: 41%;
      }
      @media only screen and (min-width: 1700px) {

        left: 500px;
      }
    }
    .wave-inner3 {
      position: relative;
    }
    .wavebackgroung3 {
      position: absolute;
      right: 50px;
      bottom: 50px;
      @media only screen and (max-width: 1100px) {
        width: 80px;
      }
    }
    .user3 {
      position: absolute;
      right: 60px;
      bottom: 150px;
      width: 137px;
      border-radius: 50%;
      @media only screen and (max-width: 1100px) {
        right: 51px;
        bottom: 95px;
        width: 78px;
      }
    }
    .wave-outer4 {
      position: absolute;
      top: 70%;
      left: 30%;
      @media only screen and (max-width: 1100px) {

      }
    }
    .wave-inner4 {
      position: relative;
    }
    .wavebackgroung4 {
      position: absolute;
      right: 50px;
      bottom: 50px;
      @media only screen and (max-width: 1100px) {
        width: 80px;
      }
    }
    .user4 {
      position: absolute;
      right: 60px;
      bottom: 150px;
      width: 137px;
      border-radius: 50%;
      @media only screen and (max-width: 1100px) {
        right: 51px;
        bottom: 95px;
        width: 78px;
      }


    }
    @media only screen and (max-width: 600px) {
      margin-bottom: 42px;
    }
  }
  .invite {
    padding: 38px 0;
    margin: 60px 80px 0 80px;
    background: url("../../assets/images/bg_lines.png") no-repeat;
    background-size: cover;
    @media only screen and (max-width: 600px) {
      background: url("../../assets/images/small_bg.png");
    }
    @media only screen and (max-width: 600px) {
      padding-top: 14px;
      padding-bottom: 31px;
      margin: 0;
    }
    .content-section{
      display: flex;
      align-items: center;
      .form-section{
        width: 60%;
        .title {
          @include heading1;
          color: #4E4E4E;
          padding-bottom: 10px;
          @media only screen and (max-width: 600px) {
            font-size: 24px;
            line-height: 120%;
            padding-bottom: 8px;
          }
        }
        .para {
          font-size: 20px;
          font-family: "GilroyRegular",sans-serif;
          color: #4E4E4E;
          line-height: 120%;
          margin-bottom: 40px;
          @media only screen and (max-width: 600px) {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 16px;
          }
        }
        .mobile-invitebg{
          display: none;
          @media screen  and (max-width: 600px){
            display: block;
            width: 50%;
            margin: 16px 0;
          }
        }
        .par-email {
          position: relative;
          .Email {
            background: #FFFFFF;
            box-shadow: 0px 1px 10px rgba(78, 78, 78, 0.15);
            border-radius: 8px;
            padding: 19px 0 18px 24px;
            width: 100%;
            border: none;
            &:focus{
              outline: none;
            }
          }
          .send {
            position: absolute;
            border-bottom-right-radius: 8px;
            right: 0;
            height: 100%;
            width: 144px;
            border-top-right-radius: 8px;
            color: white;
            background: #E9B51C;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.03em;
            border: none;
            @media only screen and (max-width: 600px) {
              width: 100px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
        .m-24 {
          margin-top: 24px;
          @media only screen and (max-width: 600px) {
            margin-top: 16px;
          }
        }
        @media screen and (max-width: 600px){
          width: 100%;
        }
      }
      .image-section{
        width: 40%;
        .invitebg {
          width: 100%;
        }
        @media screen and (max-width: 600px){
          display: none;
        }
      }
    }
  }
}



