@import '../../../assets/common/Typography';
@import '../../../assets/common/Colors';

.UpComingConsultations {
  //margin: 48px 40px 80px 40px;

  .header {
    @include heading2;
    font-size: 32px;
    color: #4E4E4E;
    margin-bottom: 40px;
  }

  .cusdataerror {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular",sans-serif;
    color: $vampire_grey;
  }

  .header1, .header3 {
    @include heading2;
    font-size: 32px;
    color: #4E4E4E;
    margin-top: 40px;
  }

  .header1 {
    .sub_heading {
      margin-top: 80px;
    }
  }

  .header3 {
    margin-top: 55px;
  }

  .bell {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .card {
    margin: 40px 0;
  }

  .text_header {
    padding: 32px 24px;
    margin-bottom: 22px;
  }

  .card_head, .card_header, .card_head_part {
    @include heading2;
    font-size: 24px;
    color: #4E4E4E;
  }

  .card_header {
    font-size: 16px;
    font-weight: 600;
    color: #8F8F8F;

  }

  .header3 {
    .card_header {
      margin-bottom: 32px;
    }
  }

  .card_head_part {
    font-size: 16px;
    color: #000000;
  }

  .btn_cancle {
    .rounded-button-component button {
      background-color: #4e4e4e;
      opacity: 0.2;
    }
  }

  .btn {
    min-width: 168px;
  }

  .block_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rounded-button-component {
    margin-right: 19px;
  }

  .card {
    max-width: 100%;
  }

  .pending_main {
    .btn {
      margin-left: 0;
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .pending_container, .pending_container1 {
    padding: 24px;
  }

  .pending_container1 {
    background: rgba(233, 181, 28, 0.05);
  }

  @media only screen and (max-width: 770px) {
    //margin: 40px 24px;
    //margin-bottom: 80px;

    .header, .header1, .header3 {
      font-size: 24px;
      margin-bottom: 24px;
      margin-top: 56px;
    }
    .header {
      margin-top: 0;
      margin-bottom: 24px;
    }
    .breact_tag {
      display: block !important;

      .block_text {
        flex-direction: row;
      }
    }
    .btn {
      min-width: 97px;
      margin-bottom: 40px;
    }
    .rounded-button-component {
      margin-right: 0;
    }
    .text_header {
      padding: 24px 16px;
      margin-bottom: 0;
    }

    .rounded-button-component {
      .bnt {
        margin-left: 0;
      }
    }
    .pending_container1, .card {
      margin-bottom: 24px;
      margin-top: 0;
    }

  }


}
