// Typorgraphy

@mixin heading1 {
    font-family: "GilroyBold", sans-serif;
    font-size: 64px;
    line-height: 115%;
    letter-spacing: 0;
}


@mixin heading2 {
    font-family: "GilroyBold", sans-serif;
    font-size: 56px;
    line-height: 115%;
    letter-spacing: 0;
}

@mixin heading3 {
    font-family: "RecoletaBold", sans-serif;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0;
}

@mixin heading4 {
    font-family: "RecoletaBold", sans-serif;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: 0;
}

@mixin heading5 {
    font-family: "GilroyBold", sans-serif;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0;
}

@mixin heading6 {
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0;
}

@mixin heading6_medium {
    font-family: "GilroyMedium",sans-serif;
    @include heading6;   
}

@mixin heading6_semibold {
    font-family: "GilroySemiBold",sans-serif;
    @include heading6; 
}

@mixin heading6_bold {
    font-family: "GilroyBold",sans-serif;
    @include heading6; 
}

@mixin paragraph1 {
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0;
}

@mixin paragraph1_light {
    font-family: "InterLight", sans-serif;
    @include paragraph1;
}

@mixin paragraph1_regular {
    font-family: "InterRegular", sans-serif;
    @include paragraph1; 
}

@mixin paragraph1_medium {
    font-family: "InterMedium", sans-serif;
    @include paragraph1; 
}

@mixin paragraph1_semibold {
    font-family: "InterSemiBold", sans-serif;
    @include paragraph1; 
}

@mixin paragraph2 {
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0;
}

@mixin paragraph2_medium {
    font-family: "GilroyMedium",sans-serif;
    @include paragraph2;    
}

@mixin paragraph2_semibold {
    font-family: "GilroySemiBold",sans-serif;
    @include paragraph2; 
}

@mixin paragraph2_bold {
    font-family: "GilroyBold",sans-serif;
    @include paragraph2;
}

@mixin paragraph2_regular {
    font-family: "GilroyRegular",sans-serif;
    @include paragraph2;
}

@mixin paragraph3{
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0;
}

@mixin paragraph3_regular {
    font-family: "GilroyRegular",sans-serif;
    @include paragraph3;
}

@mixin paragraph3_semibold {
    font-family: "GilroySemiBold",sans-serif;
    @include paragraph3;
}

@mixin paragraph4 {
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0;
}

@mixin paragraph4_regular {
    font-family: "GilroyRegular",sans-serif;
    @include paragraph4;
}

@mixin paragraph4_semibold {
    font-family: "GilroySemiBold",sans-serif;
    @include paragraph4;
}
