.modules-info-component {
  margin-top: 90px;
  min-height: 95vh;
  .preview-video-section{
    font-family: "GilroyRegular",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #4E4E4E;
  }
  .mt--3{
    margin-top: -3px;
  }
  .mr-2mt--6{
    margin-top: -6px;
    margin-right: 2px;
  }
  .important-mark-text{
    font-family: "GilroyRegular",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 175.3%;
    color: #4E4E4E;
    opacity: 0.7;
  }
  .header-section {
    .info {
      font-family: "GilroyRegular", sans-serif;
      font-size: 24px;
      line-height: 28px;
      color: #4E4E4E;
    }

    .edit-module-btn {
      background: #58A882;
      border-radius: 4px;
      border: none;
      font-family: "GilroyRegular", sans-serif;
      font-size: 20px;
      line-height: 170.1%;
      color: #FFFFFF;
      padding: 3px 12px;

      .icon {
        margin-left: 19px;
      }
    }

    .save-changes-section {
      .save-changes-btn{
        font-family: "GilroyRegular",sans-serif;
        font-size: 20px;
        line-height: 34px;
        text-decoration-line: underline;
        color: #58A882;
        cursor: pointer;
        text-align: right;
        img{
          margin-left: 12px;
        }
      }
      .action-btn-section{
        display: flex;
        gap: 20px;
        margin-top: 24px;
        .select-all-btn{
          width: 125px;
          height: 41px;
          background: #E9B51C;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          .form-group{
            margin-bottom: 0;
          }
          .form-group input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }

          .form-group label {
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            color: #FFFFFF;
            font-family: "GilroyBold",sans-serif;
            font-size: 14px;
            line-height: 25px;
          }

          .form-group label:before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 0.5px solid #FFFFFF;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            //padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 16px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
          }

          .form-group input:checked + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 9px;
            width: 6px;
            height: 14px;
            border: solid #FFFFFF;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        .delete-selected-btn{
          background: #C9C9C9;
          border-radius: 8px;
          border: none;
          margin: 0;
          color: #FFFFFF;
          font-family: "GilroyBold",sans-serif;
          font-size: 14px;
          line-height: 25px;
          padding: 8px 16px;
          text-transform: capitalize;
          box-shadow: none;
          img{
            margin-right: 16px;
          }
        }
      }

    }
  }

  .new-module-card {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    margin: 30px 0 12px 0;

    .new-module-btn {
      background: #E9B51C;
      border-radius: 4px;
      padding: 6px 18px;
      font-family: "GilroyBold", sans-serif;
      font-size: 16px;
      line-height: 28px;
      color: #000000;
      margin: 0 auto;
      text-transform: capitalize;

      .plus-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .react-accordion-with-header {
    box-shadow: none !important;
    border-radius: unset !important;

    .accordian-node {
      margin-bottom: 12px;
      transition: all 250ms ease-in-out 0s;
      &.active {
        border: 1px solid #E9B51C;
        .accordian-panel {
          max-height: 100% !important;

        }

      }
      &:not(.active){
        .accordian-panel {
          max-height:0 !important;
        }
      }

      .accordian-header {
        padding: 0 !important;
        box-shadow: none !important;
        border-radius: unset !important;
      }


    }
  }

  .edit-module-list{
    margin: 24px 0;
    .edit-module-card{
      display: flex;
      align-items: center;
      gap: 16px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .form-group label {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
      }

      .form-group label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #B9B6B6;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }

      .form-group input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #B9B6B6;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      .module-input{
        height: 78px;
        background: #FFFFFF;
        border: 0.5px solid rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        width: 100%;
        padding: 0 16px;
        &:focus{
          outline: none;
        }
      }
      .module-delete-btn{
        width: 60px;
        height: 40px;
        background: #787878;
        border-radius: 4px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .modal-close-btn{
    cursor: pointer;
  }

}

.module-header-component {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 32px;
  width: 100%;

  .title {
    color: #4E4E4E;
    font-family: "GilroySemoBold", sans-serif;
    font-size: 24px;
    line-height: 42px;
  }

  .icon-section {
    gap: 20px;

    .arrow-icon-section {
      width: 40px;
      height: 40px;
      background: #E9B51C;
      border-radius: 100%;
    }

    .edit-icon-section {
      width: 60px;
      height: 40px;
      background: #58A882;
      border-radius: 4px;
    }

    .delete-icon-section {
      width: 60px;
      height: 40px;
      background: #787878;
      border-radius: 4px;
    }
  }

  .module-divider {
    margin-top: 32px;
    margin-bottom: 0;
    border: 0;
    border-top: 0.5px solid #E9B51C;
  }
}

.edit-module-header-component{
  background: #FFFFFF;
  border-radius: 8px;
  padding: 32px;
  width: 100%;
  .header-section{
    gap: 40px;
    .module-input{
      height: 67px;
      background: #FFFFFF;
      border: 0.5px solid rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      width: 100%;
      padding: 0 16px;
      flex: 1;
      &:focus{
        outline: none;
      }
    }
    .save-changes-btn{
      font-family: "GilroyRegular",sans-serif;
      font-size: 20px;
      line-height: 34px;
      text-decoration-line: underline;
      color: #58A882;
      cursor: pointer;
      text-align: right;
      img{
        margin-left: 12px;
      }
    }
  }
  .module-divider {
    margin-top: 32px;
    margin-bottom: 0;
    border: 0;
    border-top: 0.5px solid #E9B51C;
  }

}

.module-body-component {
  background: #FFFFFF;
  padding: 24px 40px;

  .new-lesson-card {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    margin: 0 0 16px 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

    .new-lesson-btn {
      background: #E9B51C;
      border-radius: 4px;
      padding: 6px 18px;
      font-family: "GilroyBold", sans-serif;
      font-size: 16px;
      line-height: 28px;
      color: #000000;
      margin: 0 auto;
      text-transform: capitalize;

      .plus-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .lesson-card {
    padding: 11px 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 16px;

    .title {
      font-family: "GilroySemoBold", sans-serif;
      font-size: 24px;
      line-height: 42px;
      color: #787878;
    }

    .lesson-card-action-section {
      gap: 8px;

      .lesson-card-action-btn {
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        //border: 0.1px solid #000000;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 80px;
        height: 40px;
        border: none;
      }
    }
  }

}

.edit-module-body-component{
  background: #FFFFFF;
  padding: 24px 40px;
  .lesson-edit-btn-for-all{
    background-color: #C49818;
    border-radius: 50px;
  }
  .action-btn-section{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    .select-all-btn{
      width: 125px;
      height: 41px;
      background: #E9B51C;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .form-group{
        margin-bottom: 0;
      }
      .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .form-group label {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        color: #FFFFFF;
        font-family: "GilroyBold",sans-serif;
        font-size: 14px;
        line-height: 25px;
      }

      .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 0.5px solid #FFFFFF;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        //padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 16px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }

      .form-group input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #FFFFFF;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .delete-selected-btn{
      background: #C9C9C9;
      border-radius: 8px;
      border: none;
      margin: 0;
      color: #FFFFFF;
      font-family: "GilroyBold",sans-serif;
      font-size: 14px;
      line-height: 25px;
      padding: 8px 16px;
      text-transform: capitalize;
      box-shadow: none;
      img{
        margin-right: 16px;
      }
    }
  }

  .edit-lesson-list{
    margin: 24px 0;
    .edit-lesson-card{
      display: flex;
      align-items: center;
      gap: 16px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .form-group label {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
      }

      .form-group label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #B9B6B6;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }

      .form-group input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #B9B6B6;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      .lesson-input{
        height: 78px;
        background: #FFFFFF;
        border: 0.5px solid rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        width: 100%;
        padding: 0 16px;
        &:focus{
          outline: none;
        }
      }
      .lesson-delete-btn{
        margin: 0;
        padding: 10px;
        box-shadow: none;
      }
      .document-upload>input {
        display: none;
      }
      .document-card,.video-card{
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border: 0.1px solid #000000;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 146px;
        height: 86px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .edit-bar{
          background: rgba(0, 0, 0, 0.21);
          border-radius: 8px;
          width: 100%;
          position: absolute;
          bottom: 0;
          height: 23px;
        }
      }
    }
  }


}

.create-module-form{
  padding: 0 calc(60px - 1rem);
  .title{
    font-family: "GilroyBold",sans-serif;
    font-size: 40px;
    line-height: 70px;
    color: #4E4E4E;
    margin-bottom: 34px;
  }
  .form-section{
    .form-label{
      font-family: "GilroyBold",sans-serif;
      font-size: 24px;
      line-height: 29px;
      color: #4E4E4E;
      margin-bottom: 25px;
    }
    .form-input{
      width: 100%;
      height: 64px;
      padding: 20px 24px;
      background: #FFFFFF;
      border: 1px solid #E9B51C;
      border-radius: 8px;
      font-family: "GilroyRegular",sans-serif;
      font-size: 14px;
      line-height: 25px;
      color: #4E4E4E;
      opacity: 0.5;
      &:focus{
        outline: none;
      }
    }
    .submit-btn{
      button{
        width: 100%;
        margin: 24px 0 24px 0;
      }
    }
    .lesson-document-upload,.lesson-video-upload{
      //width: 290px;
      //height: 160px;
    }
    .upload-card{
      width: 290px;
      height: 160px;
      background: #FFFFFF;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      .upload-btn{
        height: 40px;
        background: #E9B51C;
        opacity: 0.8;
        border-radius: 8px;
        padding: 7px 13px;
        font-family: "GilroySemoBold",sans-serif;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        text-transform: capitalize;
        margin: 0 auto;
        img{
          margin-right: 10px;
        }
      }

    }
    .lesson-document-upload>input ,.lesson-video-upload>input{
      display: none;
    }
  }
}

.Upload-Video-Pdf-link-Container {
  .Upload-Video-Pdf-Container {
    gap: 32px;
    .lesson-name{
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }

    .upload-video-section, .upload-pdf-section {
      background-color: #F7F7F5;
      padding: 16px;
      width: 348px;
    }

    .title {
      justify-content: space-between;

      .text {
        font-family: 'GilroyRegular', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: rgba(78, 78, 78, 0.8);

        .limit {
          font-size: 12px;
          line-height: 14px;
          opacity: 0.5;
          white-space: nowrap;
          text-align: left;
        }

        p {
          margin-bottom: 4px;
        }
      }

    }

    .upload-btn {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    .custome-button {
      .basic-button-component button {
        font-size: 14px;
        font-family: 'GilroyRegular', sans-serif;
        font-weight: 600;
        padding: 12px;
        margin: 0;
        line-height: 175.3%;
      }
    }

    .main-video-section {
      background-color: #D1D1D1;
      width: 100%;
      height: 140px;
      position: relative;
      .close_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .documents-container {
      flex-wrap: wrap;
      gap: 12px;
      height: 140px;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .inside-card {
        height: 64px;
        width: 70px;
        background-color: #D1D1D1;
        position: relative;
        .inside-text{
          padding-top: 10px;
          font-size: 10px;
          text-align: center;
        }
      }

      .close_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .external-link{
    background-color: #F7F7F5;
    margin-top: 29px;
    padding: 16px 24px;
    .title-text{
      font-family: 'GilroyRegular',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: rgba(78, 78, 78, 0.8);
    }
    .title-link{
      font-family: 'GilroyRegular',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #E9B51C;
      text-align: end;
    }

    .input-text{
      width: 100%;
      padding: 13px 60px 13px 20px;
      margin-top: 18.5px;
      outline: none;
    }
    .input-text-title{
      border-bottom-left-radius: 13px;
      border-top-left-radius: 13px;
      border-left: none;
      border-top: none;
      border-bottom: none;
      border-right: 0.5px solid #D1D1D1;
    }
    .input-text-link{
      border-top-right-radius: 13px;
      border-bottom-right-radius: 13px;
      border: none;
      position: relative;
    }
    .link-close-btn{
      position: absolute;
      top: 50%;
      right: 5%;
      cursor: pointer;
    }
  }
  .redStar{
    margin-top: -15px;
  }
  .add-extra-link{
    cursor: pointer;
  }
  .testtest{
    display: none;
  }
  .label-video-upload{
    width: 100%;
    height: 100%;
    background-color: #E9B51C;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    align-items: center;
    padding: 12px;
    color: white;
    font-family: 'GilroyRegular',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 175.3%;
  }
}