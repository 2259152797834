.sidebar-component{
  padding: 38px 30px 38px 35px;
  background: #191D27;
  height: 100%;
  position: fixed;
  width: 100%;
  .menu-list{
    margin-top: 5rem;
    .menu-item{
      display: flex;
      gap: 11px;
      align-items: center;
      padding: 15px 22px;
      margin-bottom: 24px;
      cursor: pointer;
      &.signout{
        margin-top: 3rem;
      }
      &.active{
        background: #E9B51C;
        border-radius: 8px;
        .title{
          color: #000000;
        }
      }
      .title{
        font-family: "GilroyBold",sans-serif;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
        color: #FFFFFF;
        &.signout{
          color: #E9B51C;
        }
      }
    }
  }
}

.top-header-component{
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 8px 23px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 75%;
  z-index: 1000;
  .welcome-text{
    font-family: "GilroyRegular",sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #787878;
    margin: 0;
    .username{
      font-family: "GilroyBold",sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-left: 11px;
    }
  }
  .right-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .notification-section,.profile-pic-section{
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #F2F5FA;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
