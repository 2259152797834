@import "../../assets/common/Colors";
@import "../../assets/common/Typography";


.course-instructor-card-component{
  .instructor-card{
    gap: 22px;
    .instructor-image-section{
      width: 200px;
      height: 200px;
      overflow: hidden;
      background: url("../../assets/Illustrations/Polygon-Light.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
      position: relative;
      margin: 0 auto;
      // .instructor-bg{
      //   position: relative;
      //   top: 0;
      //   left: 0;
      // }
      .instructor-profile{
        width: 90%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .instructor-detail{
      .title{
        color: #C8C8C8;
        margin-bottom: 8px;
        @include heading5();
      }
      .instructor-name{
        color: $vampire_grey;
        font-family: "GilroyBold",sans-serif;
        margin-bottom: 4px;
        @include paragraph1();
      }
      .instructor-role{
        color: $vampire_grey;
        font-family: "GilroyBold",sans-serif;
        @include paragraph3();
        margin-bottom: 4px;
      }
      .instructor-institute,.instructor-address{
        color: $vampire_grey;
        font-family: "GilroyMedium",sans-serif;
        @include paragraph3();
        margin-bottom: 4px;
      }
      .instructor-address{
        margin-bottom: 8px;
      }

    }
  }
  .instructor-experience{
    color: $vampire_grey;
    font-family: "GilroyBold",sans-serif;
    @include paragraph3();
  }
  .large-device-experiance{
    display: flex;
  }
  .small-device-experiance{
    display: none;
    margin-top: 16px;
  }
}

@media (max-width: 600px) {
  .course-instructor-card-component{
    padding: 24px;
    background-image: url("../../assets/images/instructor-card-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    .instructor-card{
      gap: 16px;
      .instructor-detail{
        .title,.large-device-experiance{
          display: none;
        }
        .instructor-name{
          @include paragraph3();
        }
        .instructor-institute,.instructor-address{
          @include paragraph4_regular();
        }
        .instructor-address{
          margin-bottom: 0;
        }


      }
    }
    .small-device-experiance{
      display: flex;
    }

  }
}
