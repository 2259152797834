
.header-component{
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  .Navigation-smallLogo{
    width: 142px;
  }
  .Navigation-list{
    white-space: nowrap;
    li{
      a{
        color: #4E4E4E!important;
      }
    }
   }
  .hide-for-desktop{
    display: none;
  }
  .persone{
    padding-right: 9px;
    width: 24px;
    height: 24px;
  }
  .downbutton{
    padding-left: 3px;
  }
  .badged-list{
    position: relative;
  }
  .dropdown_active{
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 0px 0px 24px 24px;
    padding: 26px;
  }
  .dropdown_disactive{
    display: none;
  }
  .custom-badges{
    background-color: #e9b51c;
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
  }
  .BurgerMenu {
    height: 3px;
    width: 18px;
    border-radius: 1em;
    background: #043056;

    &:before {
      transition: all .25s;
      content: "";
      position: absolute;
      left: 0;
      top: -.5em;
      height: 3px;
      width: 18px;
      border-radius: 1em;
      background: #043056;
    }

    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: .5em;
      height: 3px;
      width: 18px;
      border-radius: 1em;
      background: #043056;
      transition: all .25s;
    }
  }
  .Navigation {
    padding: 16px 0;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;


    &-smallLogo{
      cursor: pointer;
    }
    &-list.mobile-navigation-list{
      display: none;
    }

    &-list {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    a {
      // inline-block allows v. alignment
      display: inline-block;
      padding: .5em;
      vertical-align: middle;
      text-decoration: none;
      transition: all .4s;
      color: #141414 !important;
    }

    li {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      font-family: 'GilroyBold', sans-serif;
      margin: 0 15px;
      position: relative;
      cursor: pointer;
    }
    li.active{

      //font-weight: 800;
      //&:after{
      //  content: '';
      //  width: 60%;
      //  height: 2px;
      //  //background-color: #FA528F;
      //  position: absolute;
      //  bottom: -15px;
      //  left: 8px;
      //  background-color: red;
      //}
      a{
        color:rgba(233, 181, 28, 1) !important;
      }
    }

    &--collapsed {
      display: none;
      position: relative;
      z-index: 1;
      cursor: pointer;
      transition: all .25s;
    }

    &-list {
      display: inline-block;
    }
  }
  .Overlay {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    .close-navbar{
      position: absolute;
      top: 40px;
      right: 40px;
      display: none;
    }
    //width: 100%;
  }
  .modal{
    .modal-dialog{
      .modal-content{
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  .hide-for-desktop{
    display: none!important;
  }

}


// media queries to show/hide elements
@media only screen and (max-width: 768px){
  // go to fixed and add margin to content
  .hide-for-desktop{
    display: block;
  }
  .header-component {
    .BurgerMenu {
      &--on {
        background-color: transparent;
        transition: all .25s;

        //&:before {
        //  transition: all .25s;
        //  content: "";
        //  transform: rotate(45deg);
        //  top: 0px;
        //}
        //
        //&:after {
        //  transition: all .25s;
        //  content: "";
        //  transform: rotate(-45deg);
        //  top: 0px;
        //}
      }
    }
    .hide-for-desktop{
      display: block!important;
    }
    .Navigation {
      //padding: 32px 24px;

      &-smallLogo{
        order: 2;
      }

      &--collapsed {
        display: block;
        order: 1;
      }

      &-list.mobile-navigation-list{
        order: 3;
        display: inline-flex;
        width: auto;
        li{
          margin: 0 16px;

        }
      }

      &-list {
        position: relative;
        height: 100%;
        padding: 0;
        li.hide-for-mobile{
          display: none;
        }

        li {
          display: block;
          position: relative;
          font-size: 20px;
          line-height: 24px;
          font-family: 'Montserrat', sans-serif;
          margin: 0;

          a {
            color: #ffffff !important;
            padding: 1.5rem 0.5rem;
          }


          //height: 25%;
          //height: calc(100% / 4);
          //min-height: 4em;
        }
        li.active:after{
          content: none;
        }
      }
    }
    .Overlay {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity .35s, visibility .35s, height .35s, left .35s;
      overflow: hidden;
      background: #1a1a1af2;
      padding: 40px;
      .close-navbar{
        display: block;
      }

      &--on {
        height: 100%;
        visibility: visible;
        opacity: 1;
        z-index: 2;
        left: 0;
      }
    }
    .modal{
      .modal-dialog{
        .modal-content{
          .modal-body{
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-component {
    .Navigation {
      li{
        margin: 0 5px;
      }
    }
  }
}





