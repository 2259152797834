//primary colors
$squash:#E9B51C;
$vampire_grey:#4E4E4E;
$white:#ffffff;

// Mamas Pick shade of greys
$squash1:rgba(233, 181, 28, 0.9);
$squash2:rgba(233, 181, 28, 0.8);
$squash3:rgba(233, 181, 28, 0.7);
$squash4:rgba(233, 181, 28, 0.6);
$squash5:rgba(233, 181, 28, 0.5);
$squash6:rgba(233, 181, 28, 0.4);
$squash7:rgba(233, 181, 28, 0.3);
$squash8:rgba(233, 181, 28, 0.2);
$squash9:rgba(233, 181, 28, 0.1);