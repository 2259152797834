@import '../../../assets/common/Typography';

.PaymentHistory {

  .orderNo {
    //margin-top: auto;
    //margin-bottom: auto;
  }

  .cusdataerror {
    @include heading3;
    font-size: 20px;
    text-align: center;
    min-height: 200px;
  }

  .title {
    @include heading2;
    font-size: 32px;
    color: #4e4e4e;
    margin-bottom: 40px;
  }

  .main_container {
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 16px 24px 24px 24px;
    margin-bottom: 40px;
  }

  .top_flex {
    justify-content: space-between;
    align-items: center;
  }

  .bottom_flex {
    justify-content: space-between;
  }

  hr {
    border-top: 1px solid #E9B51C;
  }

  .heda {
    @include heading2;
    font-size: 24px;
  }

  .orderid {
    padding-left: 16px;
    font-weight: 600;
    color: #8F8F8F;
  }

  .orders {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.005em;
    color: #4E4E4E;
  }

  .ActivePaymentDetail {

  }

  @media only screen and (max-width: 770px) {
    margin-top: 32px;
    margin-bottom: 80px;
    .top_flex {
      flex-direction: column;
      align-items: start;
    }
    .bottom_flex {
      flex-direction: column;
    }
    .main_container {
      padding: 24px;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
    }
    .flat-button-component, {
      .btn {
        margin: 16px 0;
        padding: 0;
      }
    }
    .rounded-button-component {
      .btn {
        margin-left: 0;
      }
    }
    .orders {
      padding-bottom: 32px;
    }
    .title {
      font-size: 24px;
      margin-bottom: 24px;
    }
    .orderid {
      padding-top: 16px;
    }
    .main_container {
      margin-bottom: 24px;
    }
  }
}
