@import "../../assets/common/Colors";
@import "../../assets/common/Typography";


.rounded-button-component{
  button{
    background-color: $squash;
    color: $white;
    border-radius: 32px;
    padding: 10px 24px;
    box-shadow: none;
    text-transform: capitalize;
    @include paragraph2_bold();
    &:hover,&:active,&:focus{
      box-shadow: none;
      color: $white;
    }
    .arrow-right{
      margin-left: 6px;
    }
  }
}
