@import '../../assets/common/Typography';
.AboutUs{
  .para{
    @include paragraph2_medium;
    font-weight: 500;
  }
  .AboutUs_top_header{
    @include paragraph2_bold;
    background-image: url("../../assets/images/blog_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 71px 0;
    font-size: 48px;
    line-height: 58px;
    color: white;
    text-align: center;
  }
  .AboutUsUniversityProfessors{
    margin-top: 104px;
    margin-bottom: 64px;
    .title{
      @include paragraph2_bold;
      font-size: 32px;
      line-height: 39px;
      color: #4E4E4E;
    }

  }
  .Our_vission{
    background-image: url("../../assets/images/singnup_created_bg.png");
    background-color: #FFFBEF;
    background-size: cover;
    text-align: center;
    margin: 0 -80px;
    padding-top: 43px;
    padding-bottom: 123px;
  }
  .Our_vission_title{
  @include heading2;
    font-size: 36px;
    color: #4e4e4e;
    margin-bottom: 16px;
  }
  .Our_vission_para{
    max-width: 1000px;
    margin: auto;
    padding: 0 24px;
  }
  .OurStory{
    margin: 130px 0;
    .full_left_row{
      gap: 20px;
    }
    .our_storey_1{
      margin: 20px;
    }
    .images_left{
      gap:20px;
    }
    .card{
      max-width: 500px;
      padding: 40px 50px;
     margin-right: 10px;
      margin-left: 60px;
    }
    .right_side{
      position: relative;
    }
    .shap{
      position: absolute;
      top: -20px;
      left: 20px;
      z-index: 10;
    }
    .title,.para_1,.para_2{
      @include heading5;
     line-height: 29px;
      color: #E9B51C;
    }
    .para_1{
      color: #575859;
    }
    .para_2{
      color: #B4B4B4;
    }
    .btn-1{
      width: 88px;
      height: 88px;

    }
    .story{
      @include heading3;
      line-height: 58px;
      color:#767676;
      margin-left: 30px;
      align-self: center;
    }
    .view_img{
      align-self: center;
    }
    .nextstudentbtn {
      width: 88px;
      height: 88px;
      padding: 33px;
      border: 1px dashed #e9b51c;
      border-radius: 50%;
      margin-right: 30px;
      //@media only screen and (max-width: 600px) {
      //  width: 48px;
      //  height: 48px;
      //  padding: 14px;
      //  margin-right: 24px;
      //}
    }
    .story_main_tag{
      gap: 20px;
    }

    .LeftArrow,
    .yellowrightArrow {
      width: 22px;
    }

  }


  @media only screen and (max-width: 768px) {

.AboutUs_top_header{
  padding: 17px;
  font-size: 16px;
  line-height: 19px;
}
.AboutUsUniversityProfessors{
  margin: 32px 0;
  .title{
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 23px;
  }
  .university_list{
    margin-top: 24px;
  }

}
    .Our_vission{
      margin: 0 -24px;
      padding: 31px 0;
      .Our_vission_title{
       font-size: 24px;
        line-height: 120%;
      }
      .Our_vission_para{
        font-size: 16px;
        line-height: 27px;
      }

    }
    .OurStory{
     margin: 48px 24px 81px 24px;
      .card{
        position: relative;
        margin: 0;
        padding: 16px;
        .title{
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          margin-bottom: 24px;
          color: rgba(78, 78, 78, 0.8);
          width: 85%;
        }
        .para_1,.para_2{
          font-size: 16px;
          line-height: 170%;
          color: #4e4e4e;
          margin: 0;
        }
      }
      .nextstudentbtn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        margin: 0;
      }
      .yellowrightArrow,.LeftArrow{
        width: 22px;
        height: 22px;
      }
      .story{
        font-size:24px ;
        line-height:120%;
        margin-bottom: 39px;
        margin-left: 0;
      }
    }


    .full_left_row,.shap{
      display: none!important;
    }
    .story_main_tag{
      display: block!important;
      width: 100%;
    }
    .aboutus_img_1{
      width: 100px;
      height: 100px;
    }
    .mb_view_img {
      width: 100%;
      gap: 24px;


    }
    .mb_shap{
      position: absolute;right: 0}

  }




}


