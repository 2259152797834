@import "../../assets/common/Typography";
@import "../../assets/common/Colors";

$font-color:#4E4E4E;
.wishlist-component {
  .empty-message{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "GilroyRegular",sans-serif;
    color: $vampire_grey;
  }
  .header {
    background-image: url("../../assets/images/bg_lines.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    text-align: center;
  }
  .header-font {
    @include paragraph2_bold;
    font-size: 48px;
    color: $font-color;
  }
  .CourseCard {
    position: relative;
    background: #ffffff;
    box-shadow: 16.8987px 16.8987px 25.3481px rgba(0, 0, 0, 0.05);
    border-radius: 6.75949px;
    margin-bottom: 48px;
    cursor: pointer;
    .bg {
      background-image: url("../../assets/images/coursecard-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: 147.37px;
      border-radius: 6.75949px;
      .star1 {
        position: absolute;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 75px;
        text-align: center;
        .Star {
          padding-right: 12px;
        }
        color: white;
      }
      .heart1 {
        position: absolute;
        right: 8px;
        top: 8px;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 35px;
        text-align: center;
      }
    }
    .content-section{
      padding: 21px 12px 24px;
      .courseName {
        font-family: "GilroyBold", sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
      }

      .parentUser {
        padding-top: 10px;
      }

      .RateTage {
        font-family: "GilroyBold", sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
        padding-top: 7px;
      }
      .realRate {
        line-height: 120%;
        color: #787a91;
        opacity: 0.8;
        padding-left: 16px;
        padding-right: 11px;
      }
      .tag {
        background-color: rgba(6, 123, 39, 1);
        color: white;
        padding: 2px 9px;
        border-radius: 3.37975px;
      }
      .cart-btn {
        position: absolute;
        right: 12px;
        bottom: 24px;
        border-radius: 6.75949px;
        width: 40px;
        height: 40px;
        text-align: center;
        background-color: #E9B51C;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        //padding-top: 9px;
      }
    }












  }
  .CourseCardall {
    margin-top: 64px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .wishlist-component{
    .header {
      background-image: url("../../assets/images/bg_lines.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 13px 0;
      text-align: center;
    }
    .price-section{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    .mb_cart{
      @include paragraph4_regular;
      color: #9F9F9F;
      line-height: 175%;
      background-color: transparent;
      border: none;
      padding: 10px 0;
      position: absolute;
      right: 12px;
      bottom: 24px;
    }
    .star1{
      right: 10px;
      top: 8px;
    }
    .mb_saler{
      padding: 2px 6px;
      background-color: #8EBE78;
      color: white;
      position: absolute;
      left: 0;
      top: 10px;
      border-radius: 4.81082px;
      font-family: 'Poppins',sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 12px;
    }
    .CourseCardall {
      margin: 24px 0;
      width: 100%;
    }
    .header-font{
      font-size:28px;
    }
    .grid-column{
      padding: 0;
    }
  }
}
