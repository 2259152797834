.course-card-component{
  .CourseCard {
    position: relative;
    background: #ffffff;
    box-shadow: 16.8987px 16.8987px 25.3481px rgba(0, 0, 0, 0.05);
    border-radius: 6.75949px;
    cursor: pointer;
    .bg {
      background-image: url("../../assets/images/coursecard-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: 147.37px;
      border-radius: 6.75949px;
      .star1 {
        position: absolute;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 75px;
        text-align: center;
        display: flex;

        .Star {
          padding-right: 12px;
        }

        color: white;
      }

      .heart1 {
        position: absolute;
        right: 8px;
        top: 8px;
        background: rgba(0, 0, 0, 0.21);
        border-radius: 6.75949px;
        width: 35px;
        height: 35px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .content-section{
      padding: 21px 12px 24px;

      .courseName {
        font-family: "GilroyBold", sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
      }

      .smallUser {
        padding-right: 7px;
      }

      .parentUser {
        padding-top: 10px;
        display: flex;
        align-items: center;
      }

      .RateTage {
        font-family: "GilroyBold", sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #4e4e4e;
        padding-top: 7px;
      }

      .realRate {
        line-height: 120%;
        color: #787a91;
        opacity: 0.8;
        padding-left: 16px;
        padding-right: 11px;
      }

      .tag {
        font-family: GilroyRegular,"sans-serif";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;



        background-color: rgba(6, 123, 39, 1);
        color: white;
        padding: 2px 9px;
        border-radius: 3.37975px;
      }
    }
    .cart-btn {
      position: absolute;
      right: 12px;
      bottom: 24px;
      border-radius: 6.75949px;
      width: 40px;
      height: 40px;
      text-align: center;
      cursor: pointer;
      background-color: #E9B51C;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  }
}
