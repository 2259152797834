@import "../../assets/common/Typography";

$font-color: #4E4E4E;
@mixin small-header {
  font-family: 'GilroyMedium', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #4E4E4E;
}

.ContactUs {
  .error-message{
    color: red;
    margin: -24px 0 0  0;
  }
  .mb-40{
    margin-top: -40px!important;
  }
  .header {
    background-image: url("../../assets/images/bg_lines.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    text-align: center;
  }

  .header-font {
    @include paragraph2_bold;
    font-size: 48px;
    color: $font-color;
  }

  .main-container {
    padding: 80px 0 60px 0;
  }

  .para {
    @include paragraph2_medium;
    color: $font-color;
  }

  .hr_tag {
    border-top: 1px solid #E9B51C;
  }

  .small_header {
    @include small-header;
  }

  .email {
    @include small-header;
    line-height: 28px;
    color: #E9B51C;
    font-weight: 500;
  }

  .social-buttons {
    @include small-header;
    line-height: 28px;
    font-weight: 500;
  }


  .right-side {
    border: 1px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 40px;

    .small_header {
      line-height: 175.3%;
      margin-bottom: 32px;
    }
  }

  .redStar {
    margin-top: -15px;
  }

  .Create {
    white-space: nowrap;
  }

  .form-field {
    display: flex;
    flex-direction: column;
  }

  .btn {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    border-radius: 5px;
  }

  .top-form-field {
    gap: 24px;
  }

  .form-field {
    width: 100%;
  }

  .Create {
    @include paragraph2_medium;
    line-height: 115%;
  }

  input, textarea {
    border: 0.5px solid #E9B51C;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 14px;
    margin-top: 16px;
    margin-bottom: 24px;
    &:focus-visible{
      outline-color: #e9b51c;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  textarea {
    height: 154px;
    margin-top: 16px;
    margin-bottom: 40px;
  }

  input {

  }

  @media only screen and (max-width: 720px) {
    .header {
      background-image: url("../../assets/images/bg_lines.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 13px 0;
      text-align: center;
    }
    .top-form-field{
      display: block!important;
    }
    .mb-40{
      margin-top: -24px!important;
    }
    .main-container {
      padding: 22px 0 80px 0;
      margin: 0 1px;
    }
    .right-side {
      padding: 16px;

      .small_header {
        font-size: 16px;
        line-height: 115%;
      }
    }
    .Create {
      font-size: 14px;
      line-height: 170%;
      font-weight: 500;
    }
    .btn {
      margin: 16px 0;
    }
    input {
      margin-top: 8px;
      margin-bottom: 24px;
    }
    textarea {
      margin-top: 8px;
      margin-bottom: 16px;
    }
    .title {
      margin-top: 56px;
    }
    .para {
      margin-top: 15px;
      margin-bottom: 24px;
    }
    .inquiries {
      margin: 23px 0;
    }
    .follow {
      margin-top: 43px;
      margin-bottom: 6px;
    }
    .social-buttons {
      margin: 25px 0;
    }
    .span-mail {
      color: #4E4E4E;
      padding-right: 16px;
    }
  }
}
