@import "../../assets/common/Typography";
@import "../../assets/common/Colors";


.professor-list-component {
  .mobile-title {
    display: none;
    background: #4E4E4E;
    padding: 14px 24px;

    p {
      color: $white;
      @include heading5();
      margin: 0;
    }
  }

  .professor-intro-section {
    padding: 91px 0 142px 0;

    .content-part {
      .title {
        @include heading1();
        color: $vampire_grey;
        margin-bottom: 24px;
      }

      .description {
        @include heading6();
        color: $vampire_grey;
        font-weight: 400;
        font-family: "GilroyMedium", sans-serif;
      }
    }

    .image-section {
      width: 350px;
      height: 350px;
      background: linear-gradient(182.27deg, rgba(233, 181, 28, 0.03) 9.45%, rgba(233, 181, 28, 0.8) 87.6%, #E9B51C 99.85%);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //overflow:hidden;
      position: relative;
      margin: 0 auto;

      .professor-profile-pic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 100%;

      }

      .professor-highlight-points {
        width: 130px;
        height: 130px;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px rgba(233, 181, 28, 0.1);
        border-radius: 100%;
        position: absolute;

        .points {
          font-family: "GilroyBlack", sans-serif;
          font-size: 32px;
          line-height: 40px;
          color: $squash;
          margin-bottom: 0;
        }

        .point-text {
          @include heading6_semibold();
          color: #BEBCBC;
        }

        &:nth-of-type(1) {
          top: -30px;
          left: -30px;
        }

        &:nth-of-type(2) {
          top: -30px;
          right: -30px;
        }

        &:nth-of-type(3) {
          bottom: -30px;
          left: -30px;
        }

        &:nth-of-type(4) {
          bottom: -60px;
          right: -30px;
        }
      }

    }
  }

  .professor-list-section {
    .professor-title {
      font-family: "GilroyBold", sans-serif;
      font-weight: bold;
      font-size: 48px;
      line-height: 115%;
      color: $vampire_grey;
      margin-bottom: 64px;
    }

    .load-more-btn-section {
      text-align: center;
      padding: 83px 0 120px;
    }

    .small-device-professor-list {
      display: none;
    }
  }


  .multiple-slider-item-component {
    .slick-list {margin: 0 -10px;}
    .slick-slide>div {padding: 0 10px;}
  }
}

@media screen  and (max-width: 600px){
  .professor-list-component{
    .mobile-title{
      display: block;
      background: #4E4E4E;
      color: $white;
    }
    .professor-intro-section{
      padding: 77px 0 40px;
      .grid-column{
        &:nth-of-type(1){
          order:2;
          padding-top: 47px;
        }
        &:nth-of-type(2){
          order:1;
        }
      }
      .content-part{
        .title{
          display: none;
        }

        .description{
          text-align: center;
        }
      }
      .image-section{
        width: 254px;
        height: 254px;
        .professor-highlight-points{
          width: 80px;
          height: 80px;
          .points{
            font-size: 20px;
          }
          .point-text{
            font-size: 10px;
          }
          &:nth-of-type(1){
            top: -15px;
            left: 0;
          }
          &:nth-of-type(2){
            top: 0;
            right: -15px;
          }
          &:nth-of-type(3){
            bottom: -15px;
            left: -15px;
          }
          &:nth-of-type(4){
            bottom: -15px;
            right: -15px;
          }
        }

      }
    }
    .professor-list-section{
      .professor-title{
        font-size: 24px;
        margin-bottom: 40px;
      }
      .large-device-professor-list{
        display: none;
      }
      .small-device-professor-list{
        display: block;
      }
      .load-more-btn-section{
        padding: 80px 0;
      }
    }

  }
}
